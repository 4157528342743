
import { defineComponent, ref, unref } from '@nuxtjs/composition-api'
import debounce from 'lodash/debounce'

export default defineComponent({
  props: {
    value: { type: String, required: false, default: '' },
    placeholder: { type: String, required: true },
    icon: { type: String, required: false, default: null },
    large: { type: Boolean, required: false, default: false },
    autofocus: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const input = ref(undefined)

    return {
      input,
      focus: () => unref(input)?.focus(),
      inputEvent: debounce(() => {
        // use debounce to delay the search term feedback
        // this should prevent the value being send in "fragments", which is handy if used as query params
        // be wary that debounce could also be used in other places like the store
        emit('input', input.value.value)
      }, 500),
      submit: () => emit('submit', input.value.value),
    }
  },
})
