/**
 * This directive will execute a function of your choice when the element comes into or goes out of the viewport
 *
 * The function is called with { el, visible, visibleRatio }
 * - el: the element that came into or went out of the viewport
 * - visible: true when the element came into the viewport and otherwise false
 * - visibleRatio: a number that indicates how much of the element is visible, i.e. 0.25 is 25% visible
 *
 * Call onViewport when the page loads, on scroll events, when the element is both inside and outside the viewport
 * ```html
 * <div v-viewport="onViewport">...</div>
 * ```
 *
 * The modifiers allow you to specify under which circumstances the function is called:
 * - no-initial: the function is not called on page-load
 * - no-scroll: the function is not called after a scroll event
 * - no-in: the function is not called when the element is within the viewport
 * - no-out: the function is not called when the element is not-within the viewport
 * - once: the function is only called once
 * - ^[0-9]+%$: any number followed by '%' will be used as the percentage of the element that must be visible, defaults to 0
 * - ^[0-9]+ms$: any number followed by ms will be used as the debounce delay, defaults to 10
 *
 * For example: trigger GTM/SEO events when an element is 25% visible for the first time
 * ```html
 * <div v-viewport.25%.no-out.once="onViewport">...</div>
 * ```
 */

import debounce from 'lodash/debounce'
import Vue from 'vue'

const computeVisibleRatio = (el) => {
  const rect = el.getBoundingClientRect()
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight

  // Check if the element is above the viewport
  //
  //      E
  // +---------+
  // |         |
  // |         |
  // |         |
  // +---------+
  if (rect.bottom < 0) {
    return 0
  }

  // Check if the element is below the viewport
  //
  // +---------+
  // |         |
  // |         |
  // |         |
  // +---------+
  //      E
  if (rect.top > viewportHeight) {
    return 0
  }

  // Check if the element entirely within the viewport
  //
  // +---------+
  // |         |
  // |    E    |
  // |         |
  // +---------+
  if (rect.top > 0 && rect.bottom < viewportHeight) {
    return 100
  }

  // Check if the bottom part of the element is element within the viewport
  //
  //      E
  // +----E----+
  // |    E    |
  // |         |
  // |         |
  // +---------+
  if (rect.top < 0 && rect.bottom < viewportHeight) {
    return rect.bottom / rect.height
  }

  // Check if the top part of the element is element within the viewport
  //
  // +---------+
  // |         |
  // |         |
  // |    E    |
  // +----E----+
  //      E
  if (rect.top < viewportHeight && rect.bottom > viewportHeight) {
    return (viewportHeight - rect.top) / rect.height
  }

  // Check if the element is larger than the viewport
  //
  // +----E----+
  // |    E    |
  // +----E----+
  if (rect.top < 0 && rect.bottom > viewportHeight) {
    return 100
  }

  return 0
}

Vue.directive('viewport', {
  bind(el, binding) {
    // The binding must be a function that we can call when the criteria match
    if (typeof binding.value !== 'function') {
      // eslint-disable-next-line no-console
      console.error('viewport: the provided value is not a callable function', binding.value)
      return
    }

    // Grab the modifiers
    const _initial = !binding.modifiers['no-initial']
    const _in = !binding.modifiers['no-in']
    const _out = !binding.modifiers['no-out']
    const _once = binding.modifiers.once
    let threshold = 0
    let delay = 10
    for (const key of Object.keys(binding.modifiers)) {
      if (/^[0-9]+%$/.test(key)) {
        threshold = parseInt(key, 10) / 100
      } else if (/^[0-9]+ms$/.test(key)) {
        delay = parseInt(key, 10)
      }
    }

    let visibleRatio = computeVisibleRatio(el)
    let visible = visibleRatio > threshold
    if (_initial) {
      if ((visible && _in) || (!visible && _out)) {
        binding.value({ el, visible, visibleRatio })
        if (_once) {
          return
        }
      }
    }

    el._viewportDirective_onScroll = debounce(() => {
      visibleRatio = computeVisibleRatio(el)
      if (visible !== visibleRatio > threshold) {
        visible = visibleRatio > threshold
        if ((visible && _in) || (!visible && _out)) {
          binding.value({ el, visible, visibleRatio })
          if (_once) {
            document.removeEventListener('scroll', el._viewportDirective_onScroll)
          }
        }
      }
    }, delay)

    document.addEventListener('scroll', el._viewportDirective_onScroll)
  },
  unbind(el) {
    document.removeEventListener('scroll', el._viewportDirective_onScroll)
  },
})
