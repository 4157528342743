import locale7b21c2b2 from '../../config/lang/translation-loader.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentTranslationWarn":true,"defaultLocale":"nl","fallbackLocale":"nl","numberFormats":{"nl":{"currency":{"style":"currency","currency":"EUR"}},"en":{"currency":{"style":"currency","currency":"EUR"}}},"dateTimeFormats":{"nl":{"short-time":{"hour":"numeric","minute":"numeric","timeZone":"Europe/Amsterdam"},"short-date":{"month":"short","day":"numeric","weekday":"short","timeZone":"Europe/Amsterdam"},"long-date":{"month":"short","day":"numeric","weekday":"short","year":"numeric","timeZone":"Europe/Amsterdam"},"short-date-time":{"month":"short","day":"numeric","weekday":"short","hour":"numeric","minute":"numeric","timeZone":"Europe/Amsterdam"},"long-date-time":{"year":"numeric","month":"short","day":"numeric","weekday":"short","hour":"numeric","minute":"numeric","timeZone":"Europe/Amsterdam"}},"en":{"short-time":{"hour":"numeric","minute":"numeric","timeZone":"Europe/Amsterdam"},"short-date":{"month":"short","day":"numeric","weekday":"short","timeZone":"Europe/Amsterdam"},"long-date":{"month":"short","day":"numeric","weekday":"short","year":"numeric","timeZone":"Europe/Amsterdam"},"short-date-time":{"month":"short","day":"numeric","weekday":"short","hour":"numeric","minute":"numeric","timeZone":"Europe/Amsterdam"},"long-date-time":{"year":"numeric","month":"short","day":"numeric","weekday":"short","hour":"numeric","minute":"numeric","timeZone":"Europe/Amsterdam"}}}},
  vueI18nLoader: false,
  locales: [{"code":"nl","file":"translation-loader.js"},{"code":"en","file":"translation-loader.js"}],
  defaultLocale: "nl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "config/lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"nl","file":"translation-loader.js"},{"code":"en","file":"translation-loader.js"}],
  localeCodes: ["nl","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "t",
  3: "r",
  4: "a",
  5: "n",
  6: "s",
  7: "l",
  8: "a",
  9: "t",
  10: "i",
  11: "o",
  12: "n",
  13: "-",
  14: "l",
  15: "o",
  16: "a",
  17: "d",
  18: "e",
  19: "r",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: ":",
  25: "\"",
  26: ".",
  27: ".",
  28: "/",
  29: "c",
  30: "o",
  31: "n",
  32: "f",
  33: "i",
  34: "g",
  35: "/",
  36: "l",
  37: "a",
  38: "n",
  39: "g",
  40: "/",
  41: "t",
  42: "r",
  43: "a",
  44: "n",
  45: "s",
  46: "l",
  47: "a",
  48: "t",
  49: "i",
  50: "o",
  51: "n",
  52: "-",
  53: "l",
  54: "o",
  55: "a",
  56: "d",
  57: "e",
  58: "r",
  59: ".",
  60: "j",
  61: "s",
  62: "\"",
  63: "}",
}

export const localeMessages = {
  'translation-loader.js': () => Promise.resolve(locale7b21c2b2),
}
