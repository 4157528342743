export const state = () => {
  return {
    // Search context
    page: 1,

    // Search results
    pages: [], // our main data source
    hits: [],
    loading: true,
    totalHits: 0,
  }
}

export const getters = {
  // Constants
  hitsPerPage: () => 12,

  // Search context
  page: (state) => state.page,

  urlParameters: (state) => ({
    page: state.page === 1 ? undefined : state.page,
  }),
  pages: (state) => state.pages,
  hits: (state) => state.hits,
  loading: (state) => state.loading,
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Initialize
  initialize: (state, { page }) => {
    // When there are no values given, i.e. from the URL, we will simply keep the most recently used search context
    if (page === undefined) {
      return
    }

    state.page = typeof page === 'string' ? Math.max(1, Number.parseInt(page)) : 1
  },

  // Url parameters
  setUrlParameters(state, query) {
    const toValue = (value, fallback) => (value === undefined ? fallback : Array.isArray(value) ? value?.[0] : value)
    state.page = Math.max(1, toValue(query.page, 1))
    this.dispatch('press-overview/fetch')
  },
  reset(state) {
    state.page = 1
    // note that we do NOT reset the state.tags because this is not a facet that can be set by the user
    this.dispatch('press-overview/fetch')
  },
  // Search context
  setPage(state, page) {
    state.page = Math.max(1, page)
    this.dispatch('press-overview/fetch')
  },
  // Search results
  setHits: (state, hits) => (state.hits = hits),
  setTotalHits: (state, totalHits) => (state.totalHits = totalHits),
  setPages: (state, pages) => (state.pages = pages),
  setLoading: (state, loading) => (state.loading = loading),
}

export const actions = {
  async fetch({ commit, dispatch, getters }) {
    const { hitsPerPage, page } = getters

    const key = ['presses', this.$i18n.locale].join(';')
    const ttl = 3600

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query searchPress($site: [String]) {
          elasticSearch(site: $site, section: "press", orderBy: "postDate DESC") {
            count
            hits(limit: 1024) {
              uri
            }
          }
        }
        `,
        variables: {
          site: `${this.$i18n.locale}Default`,
        },
      })

      return { hits: elasticSearch.hits.map(({ uri }) => uri), totalHits: elasticSearch.count }
    }

    // Fetch the hits
    const { hits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { hits: [], totalHits: 0 } }, { root: true })
    commit('setHits', hits)
    commit('setTotalHits', totalHits)

    // todo: add loaders
    const slice = hits.slice((page - 1) * hitsPerPage, page * hitsPerPage)

    // Fetch all associated entries within the current page
    const pages = await Promise.all(slice.map((uri) => dispatch('page/fetchUri', { uri }, { root: true })))
    commit(
      'setPages',
      pages.filter((page) => page)
    )
    commit('setLoading', false)
    return pages
  },
}
