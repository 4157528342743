
import { mapGetters, mapMutations } from 'vuex'
import { defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/util'

export default defineComponent({
  setup() {
    return {
      ...setupTranslationPrefixer('ControlPanel'),
    }
  },
  data() {
    return {
      isOpen: this.isOpen,
    }
  },
  computed: {
    ...mapGetters({
      isEnabled: 'control-panel/isEnabled',
      page: 'page/page',
      translations: 'control-panel/translations',
      translationUrls: 'control-panel/translationUrls',
    }),
    urls() {
      return Object.entries(this.page?.controlPanel || {})
        .filter(([, url]) => typeof url === 'string' && /^https?:\/\//i.test(url))
        .map(([label, url]) => ({ label, url }))
    },
  },
  created() {
    if (/yes|true|1/.test(this.$route.query.cp)) {
      this.$store.commit('control-panel/enable')
    }

    this.$store.dispatch('control-panel/fetchTranslationUrls')
  },
  methods: {
    ...mapMutations({
      reset: 'control-panel/reset',
      toggle: 'control-panel/toggle',
    }),
    toggleOpen() {
      this.isOpen = !this.isOpen
    },
  },
})
