import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin(({ app, store }) => {
  app.router.afterEach((to, from) => {
    store.commit('navigation/setRoute', to)

    // Reset the search parameters whenever we switch language
    const fromLocale = from?.name?.match(/___([a-z]{2})$/)?.[1]
    const toLocale = to?.name?.match(/___([a-z]{2})$/)?.[1]
    if (fromLocale && toLocale && fromLocale !== toLocale) {
      store.commit('discovery-overview/reset')
      store.commit('event-overview/reset')
      store.commit('search/reset')
      store.commit('series-overview/reset')
    }
  })
})
