import kebabCase from 'lodash/kebabCase'
import { useContext } from '@nuxtjs/composition-api'

export function first(items) {
  return items?.[0] || {}
}

export function hasFirst(items) {
  return Array.isArray(items) && items.length > 0
}

export function isEmpty(item) {
  return item === null || item === undefined || Object.keys(item).length === 0
}

export function setupTranslationPrefixer(componentName, groupName = 'default') {
  const { i18n } = useContext()
  const componentName_ = kebabCase(componentName)
  const groupName_ = kebabCase(groupName)
  return {
    translationComponent_: componentName_,
    translationGroup_: groupName_,

    // Helper functions that prefix and normalize the translation key
    k_: (key) => key && [componentName_, groupName_, kebabCase(key)].join('.'),
    t_: (key, ...args) => key && i18n.t([componentName_, groupName_, kebabCase(key)].join('.'), ...args),
    tc_: (key, ...args) => key && i18n.tc([componentName_, groupName_, kebabCase(key)].join('.'), ...args),
    te_: (key, ...args) => key && i18n.te([componentName_, groupName_, kebabCase(key)].join('.'), ...args),

    // Helper function that automatically converts strings into Date objects and supports the 'iso' format
    d_: (value, pattern) => {
      if (!value) {
        return ''
      }
      const date = typeof value === 'object' ? value : new Date(value)
      return pattern === 'iso' ? date.toISOString() : i18n?.d(date, pattern)
    },
  }
}

export function toStringArray(value) {
  if (typeof value === 'string') {
    return [value]
  }
  if (Array.isArray(value) && value.every((val) => typeof val === 'string')) {
    return value
  }

  return []
}

export function toNumber(value, fallback = 0) {
  if (typeof value === 'string') {
    return Number.parseInt(value)
  }

  if (typeof value === 'number') {
    return value
  }

  return fallback
}

export function getDay(date, fallback) {
  if (!date) {
    return fallback
  }
  const parts = date.toJSON().match(/(\d+)/g)
  // parts[0], parts[1]-1, parts[2] because months are 0 based
  return parts[2]
}

export function toDateString(date, fallback) {
  if (!date) {
    return fallback
  }
  return [
    date.getFullYear().toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false }),
    (date.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
    // JVDH: daylight saving time issue, because getDate() uses local time
    // date.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
    getDay(date).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
  ].join('-')
}

/**
 * Assuming `date` is in UTC return its YYYY-MM-DD in CEST
 */
export function toCESTDateString(utcDate, fallback) {
  if (!utcDate) {
    return fallback
  }

  const date = new Date(toDate(utcDate).getTime())
  // Add 2 hours offset to switch from UTC to CEST
  date.setHours(date.getHours() + 2)
  return toDateString(date)
}

export function fromCESTDateString(cestDateString, fallback) {
  if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(cestDateString)) {
    return fallback
  }

  const date = new Date(`${cestDateString}T00:00:00.000Z`)
  // Subtract 2 hours offset to switch from CEST to UTC
  date.setHours(date.getHours() - 2)
  return date
}

export function toDate(value, fallback) {
  if (value instanceof Date) {
    return value
  }

  if (typeof value === 'string') {
    switch (value) {
      case 'now':
        return new Date()
      case 'today': {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        return date
      }
      default:
        return new Date(value)
    }
  }

  return fallback
}
