/**
 * This file is mandatory because V-Calendar does not work in SSR mode
 * i.e. on component level, therefore we load it as a plugin without SSR
 */
import Vue from 'vue'
import VCalendar from 'v-calendar'

Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
  locales: {
    nl: {
      firstDayOfWeek: 1,
      monthNames: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'October', 'November', 'December'],
    },
    en: {
      firstDayOfWeek: 1,
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    },
  },
})
