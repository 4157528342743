/**
 * Explode a URL into its components
 *
 * <protocol>://<user>:<password>@<hostname>:<port>/<path>?<search>#<hash>
 */
export function explodeUrl(url) {
  const groups =
    url.match(
      /^(mailto:(?<mailto>.+))|(tel:(?<tel>.+))|((((?<protocol>https?):)?\/\/)?((?<username>.+?)(:(?<password>.+))?@)?((?<hostname>[^/:?#]+)(:(?<port>[0-9]+?))?)?(?<path>\/.*?)?(\?(?<search>.+?))?(#(?<hash>.+))?)$/
    )?.groups || {}

  const protocol = groups.mailto ? 'mailto' : groups.tel ? 'tel' : groups.protocol

  return {
    ...groups,
    protocol,
  }
}

function createRelativeUrl(explodedUrl) {
  return `${explodedUrl.path || ''}${explodedUrl.search ? `?${explodedUrl.search}` : ''}${explodedUrl.hash ? `#${explodedUrl.hash}` : ''}`
}

function createAbsoluteUrl(explodedUrl) {
  switch (explodedUrl.protocol) {
    case 'mailto':
      return `mailto:${explodedUrl.mailto}`
    case 'tel':
      return `tel:${explodedUrl.tel}`
    default:
      return `//${explodedUrl.hostname}${explodedUrl.port ? `:${explodedUrl.port}` : ''}${explodedUrl.path}${explodedUrl.search ? `?${explodedUrl.search}` : ''}${
        explodedUrl.hash ? `#${explodedUrl.hash}` : ''
      }`
  }
}

function getDistance(sourceHostname, targetHostname) {
  if (!sourceHostname || !targetHostname) {
    return 0
  }

  const sourceParts = sourceHostname.split('.').reverse()
  const targetParts = targetHostname.split('.').reverse()

  if (sourceParts?.[0] !== targetParts?.[0]) {
    return -1
  }

  if (!(sourceParts?.[0] === 'test' || targetParts?.[0] === 'test') && sourceParts?.[1] !== targetParts?.[1]) {
    return -1
  }

  for (let index = 0; index < sourceParts.length; index++) {
    if (sourceParts[index] !== targetParts[index]) {
      return Math.max(sourceParts.length, targetParts.length) - index
    }
  }

  return 0
}

/**
 * Provides information on a link
 *
 * Returns { distance, scope, type, url }
 *
 * Distance: is a number indicating how far the source and target host are from each other
 * For example, from www.example.com to subdomain.example.com have distance 1
 *
 * Scope: is either 'inside' or 'outside'
 * For example, from www.example.com to subdomain.example.com is 'inside'
 * and from www.example.com to www.zicht.nl is 'outside'
 *
 * Type: is either 'anchor', 'web' or 'other'
 * For example:
 * - to #anchor is 'anchor' and from /foo to /foo#anchor is 'anchor'
 * - to http://www.example.com/bar is 'web'
 * - to mailto:test@example.com is 'other'
 *
 * Url: is the the reformatted target url
 *
 * Example where the link stays on example.com
 * > linkDetails('//example.com/foo', '//www.example.com/bar', { rewrites:[['www.example.com', 'example.com']] })
 * > --> { distance: 1, scope: 'inside', type: 'web', url: '/bar' }
 *
 * Example where the link goes to a completely different zicht.nl
 * > linkDetails('//example.com/foo', '//www.zicht.nl/bar')
 * > --> { distance: -1, scope: 'outside', type: 'web', url: '//www.zicht.nl/bar' }
 *
 * @todo consider moving this code into a library for better re-usability
 */
export function linkDetails(sourceUrl, targetUrl, options) {
  // Explode the url into its parts, and perform possible rewrites
  const source = explodeUrl(sourceUrl || '')
  const target = explodeUrl(targetUrl || '')

  // Convert lists of rewrites into a map
  // [['www.example.com', 'example.com']] -> {'www.example.com': 'www.example.com', 'example.com': 'www.example.com'}
  const rewriteMap = Object.fromEntries((options?.rewrites || []).flatMap((rewrites) => rewrites.map((rewrite) => [rewrite, rewrites[0]])))
  source.hostname = rewriteMap[source.hostname] || source.hostname || undefined
  target.hostname = rewriteMap[target.hostname] || target.hostname || undefined

  // The link `type` determines whether it links to a web page or something else, i.e. an email link
  const type =
    target.hash &&
    [undefined, source.hostname].includes(target.hostname) &&
    [undefined, source.port].includes(target.port) &&
    [undefined, source.path].includes(target.path) &&
    [undefined, source.search].includes(target.search)
      ? 'anchor'
      : [undefined, 'http', 'https'].includes(target.protocol)
      ? 'web'
      : 'other'

  // The link `scope` determines whether it links to `inside` or `outside` the app
  // const scope = type === 'web' && [undefined, source.host].includes(target.host) ? 'inside' : 'outside'
  const scope = ['anchor', 'web'].includes(type) && (source.hostname === undefined || [undefined, source.hostname].includes(target.hostname)) ? 'inside' : 'outside'

  // The link `distance` is the distance between source and target host, i.e. example.com and subdomain.example.com have distance `1`
  const distance = ['anchor', 'web'].includes(type) ? (scope === 'inside' ? 0 : getDistance(source.hostname, target.hostname)) : -1

  // The link `url` is a relative url when `inside` the app or otherwise an absolute url
  const url =
    scope === 'inside'
      ? // For inside links we will always create a relative url
        type === 'anchor'
        ? `#${target.hash}`
        : createRelativeUrl(target)
      : ['anchor', 'web'].includes(type)
      ? // For outside web links we will always create an absolute url that starts with '//' instead of the specified target protocol
        createAbsoluteUrl(target)
      : // Fallback to the original targetUrl
        `${targetUrl || ''}`

  return { distance, scope, type, url }
}
