export const state = () => ({
  page: undefined,
})

export const getters = {
  page: (state) => state.page,
  languageSwitches: (state) => {
    const page = state.page
    return (page?.alternativeLanguages || []).map(({ language, url }) => ({ language, url, isCurrent: url === page.url }))
  },
}

export const mutations = {
  setPage(state, page) {
    state.page = page
  },
}

export const actions = {
  async fetchUri({ commit, dispatch }, { uri, push, options }) {
    const locale = this.$i18n.locale
    const key = `page;locale:${locale};uri:${uri}`
    const ttl = 300

    const fetcher = async () => {
      return await this.$cmsFetch(`/api/default/${locale}/page.json?url=${encodeURI(uri)}`, { ...options })
    }

    const page = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    if (push) {
      commit('setPage', page)
    }
    return page
  },
  async fetchEducationRedirect({ commit, dispatch }, { uri, eventGroupId, eventId, push }) {
    const locale = this.$i18n.locale
    const key = `education;locale:${locale};gid:${eventGroupId};eid:${eventId};uri:${uri}`
    const ttl = 300

    const fetcher = async () => {
      return await this.$cmsFetch(`/api/${locale}/education-redirect/${eventGroupId}/event/${eventId}/${encodeURI(uri)}.json`)
    }

    const page = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    if (push) {
      commit('setPage', page)
    }
    return page
  },
}
