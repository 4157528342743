import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import { computed, onMounted, unref, useContext, useStore, watch } from '@nuxtjs/composition-api'

function eventItemName(event) {
  const date = new Date(event.eventDate)
  const datePart = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
  const timePart = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`
  return `${event.production?.title || 'Unknown'}-${datePart} ${timePart}`
}

/**
 * An event whenever a page is visited by clicking in the navigation tree
 */
export function setupGtmNavigationClick() {
  if (process.client) {
    const { $gtm } = useContext()
    return (label) =>
      $gtm.push({
        event: 'navigation_click',
        events: {
          category: 'navigation',
          action: 'navigation_click',
          label,
        },
      })
  }
  return () => {}
}

/**
 * An event whenever a page is visited
 */
export function setupGtmPageView(page) {
  if (process.client) {
    const { $gtm } = useContext()
    // Schedule a GTM event with custom page data
    // Note that we mimic the 250ms delay used by the gtm-module
    // https://github.com/nuxt-community/gtm-module/blob/master/lib/plugin.js#L76
    setTimeout(() => {
      $gtm.push({
        event: 'page_view',
        page: {
          category: page?.__typename,
          language_code: page?.language,
        },
      })
    }, 250)
  }
}

/**
 * An event with the user information
 */
export function setupGtmUserInfo() {
  if (process.client) {
    const { $gtm } = useContext()
    const store = useStore()
    // Listen to changes to the Tix state
    watch(store.state.tix, ({ user, customerId }) => {
      setTimeout(() => {
        $gtm.push({
          event: 'user_info',
          user: {
            logged_in: !!user?.id,
            user_id: user?.id,
            customer_id: customerId,
          },
        })
      }, 250)
    })
  }
}

/**
 * An event that shows the listed items on the page, for CGBR this is usually a list with events
 */
export function setupGtmViewItemList(listName) {
  if (process.client) {
    const { $gtm } = useContext()
    const listNameMap = { calendar: 1, NETA: 2 }
    return (events, offset) =>
      setTimeout(() => {
        $gtm.push({
          event: 'view_item_list',
          ecommerce: {
            items: events.map((event, index) => ({
              index: offset + index,
              item_list_name: listName,
              item_list_id: listNameMap[listName],
              item_brand: event.production?.organizer?.title || 'Unknown',
              item_category: event.production?.title || 'Unknown',
              item_category_2: event.room?.title || 'Unknown',
              item_date: event.eventDate || 'Unknown',
              item_genre: event.production?.genres?.map(({ title }) => title)?.join(', ') || 'Unknown',
              item_id: event.eventIdentifier || 'Unknown',
              item_name: eventItemName(event),
              price: event.minPrice,
              quantity: 1,
            })),
          },
        })
      }, 250)
  }
  return () => {}
}

/**
 * This is the setup for a watcher that sends the view_item_list event with all the events on the page
 *
 * Note that we will want to remove this in the future in favor of sending separate view_item_list events
 * every time one of the items scrolls into view.  This is currently already done for the NETA events,
 * see EventDetailPageNeta.vue
 */
export function setupGtmViewItemListWatcher(storeStateName) {
  const viewItemList = setupGtmViewItemList('calendar')
  const store = useStore()
  onMounted(() => viewItemList(store.getters[storeStateName], 0))
  if (process.client) {
    watch(
      computed(() => store.getters[storeStateName]),
      (events) => viewItemList(events, 0)
    )
  }
}

/**
 * An event that shows the rendered item on the page, for CGBR this is usually an event detail page
 */
export function setupGtmViewItem(page) {
  if (process.client) {
    const { $gtm } = useContext()
    setTimeout(() => {
      $gtm.push({
        event: 'view_item',
        ecommerce: {
          items: [
            {
              index: 0,
              item_brand: page.production?.organizer?.title || 'Unknown',
              item_category: page.production?.title || 'Unknown',
              item_category_2: page.room?.title || 'Unknown',
              item_date: page.eventDate || 'Unknown',
              item_genre: page.production?.genres?.map(({ title }) => title)?.join(', ') || 'Unknown',
              item_id: page.eventIdentifier || 'Unknown',
              item_name: eventItemName(page),
              price: page.minPrice,
              quantity: 1,
            },
          ],
        },
      })
    }, 250)
  }
}

/**
 * An event that is triggered when an order button is clicked
 */
export function setupGtmClickOrderTickets(page) {
  if (process.client) {
    const { $gtm } = useContext()
    return () => {
      $gtm.push({
        event: 'click_order_tickets',
        ecommerce: {
          items: [
            {
              index: 0,
              item_brand: page.production?.organizer?.title || 'Unknown',
              item_category: page.production?.title || 'Unknown',
              item_category_2: page.room?.title || 'Unknown',
              item_date: page.eventDate || 'Unknown',
              item_genre: page.production?.genres?.map(({ title }) => title)?.join(', ') || 'Unknown',
              item_id: page.eventIdentifier || 'Unknown',
              item_name: eventItemName(page),
              price: page.minPrice,
              quantity: 1,
            },
          ],
        },
      })
    }
  }
  return () => {}
}

/**
 * An event whenever a promotion is displayed to the user
 */
export function setupGtmPromoView() {
  if (process.client) {
    const { $gtm } = useContext()
    return (promotions) => {
      $gtm.push({
        event: 'promotionView',
        ecommerce: {
          promoView: {
            promotions,
          },
        },
      })
    }
  }
  return () => {}
}

/**
 * An event whenever a promotion is clicked by the user
 */
export function setupGtmPromoClick() {
  if (process.client) {
    const { $gtm } = useContext()
    return function (promotions) {
      $gtm.push({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions,
          },
        },
      })
    }
  }
  return () => {}
}

/**
 * An event whenever search filter is activated, i.e. a facet is enabled
 */
export function setupGtmFilterStart() {
  if (process.client) {
    const { $gtm, route } = useContext()
    const path = unref(route).path
    return function () {
      $gtm.push({
        event: 'filter',
        eventCategory: 'filter_start',
        eventAction: path,
      })
    }
  }
  return () => {}
}

/**
 * An event whenever search filter is activated, i.e. a facet is enabled
 */
export function setupGtmFilterMenuClose() {
  if (process.client) {
    const { $gtm } = useContext()
    return function () {
      $gtm.push({
        event: 'filter_menuclose',
      })
    }
  }
  return () => {}
}

/**
 * An event whenever a search filter is selected, i.e. a list of facets is toggled open
 */
export function setupGtmFilterSelect() {
  if (process.client) {
    const { $gtm } = useContext()
    const store = useStore()

    watch(
      computed(() => store.getters['event-overview/activeFacets']),
      (newActiveFacets, oldActiveFacets) => {
        setTimeout(() => {
          // Compute the difference to ensure that we only trigger a GTM event when a facet was added
          for (const { facet, value } of differenceWith(newActiveFacets, oldActiveFacets, isEqual)) {
            $gtm.push({
              event: 'filter',
              eventCategory: 'filter_select',
              eventAction: facet,
              eventLabel: String(value).toLowerCase(),
            })
          }
        })
      }
    )
  }
}

/**
 * An event whenever search filter is removed, i.e. a facet is disabled
 */
export function setupGtmFilterRemove() {
  if (process.client) {
    const { $gtm } = useContext()
    const store = useStore()

    watch(
      computed(() => store.getters['event-overview/activeFacets']),
      (newActiveFacets, oldActiveFacets) => {
        setTimeout(() => {
          // Compute the difference to ensure that we only trigger a GTM event when a facet was removed
          for (const { facet, value } of differenceWith(oldActiveFacets, newActiveFacets, isEqual)) {
            $gtm.push({
              event: 'filter_remove',
              filter_name: value,
              filter_category: facet,
              click_location: 'Text',
            })
          }
        })
      }
    )
  }
  return () => {}
}

/**
 * An event whenever date filter is activated, i.e. a facet is enabled
 */

export function setupGtmDateFilterStart() {
  if (process.client) {
    const { $gtm } = useContext()
    return function () {
      $gtm.push({
        event: 'date_filter_start',
      })
    }
  }
  return () => {}
}

/**
 * An event whenever date filter is set, i.e. date filter selected
 */

export function setupGtmDateFilter() {
  if (process.client) {
    const { $gtm } = useContext()
    return function (date) {
      $gtm.push({
        event: 'date_filter',
        selected_date: date,
      })
    }
  }
  return () => {}
}

/**
 * An event whenever date filter is removed, i.e. tied to setupGtmFilterRemoved
 */

export function setupGtmDateFilterRemove() {
  if (process.client) {
    const { $gtm } = useContext()
    return function (date) {
      $gtm.push({
        event: 'filter_remove',
        filter_name: date,
        filter_category: 'Date',
        click_location: 'Text',
      })
    }
  }
  return () => {}
}

export function setupGtmRecommendationView(page) {
  if (process.client) {
    const { $gtm } = useContext()
    return (event, { added, removed, newPath, oldPath }) => {
      $gtm.push({
        event: `recommendation_view_${event}`,
        page: {
          added,
          category: page?.__typename,
          language_code: page?.language,
          newPath,
          oldPath,
          removed,
          url: page?.url,
        },
      })
    }
  }
  return () => {}
}

export function setupGtmRecommendationStart() {
  const { $gtm } = useContext()
  return () => {
    if (process.client) {
      $gtm.push({
        event: 'start_keuzehulp',
      })
    }
  }
}

export function setupGtmRecommendationResults() {
  const { $gtm } = useContext()
  return () => {
    if (process.client) {
      $gtm.push({
        event: 'end_keuzehulp',
      })
    }
  }
}

export function setupGtmCdpEvent(event) {
  if (process.client) {
    const { $gtm } = useContext()
    setTimeout(() => {
      $gtm.push({
        event,
      })
    }, 250)
  }
}

export function setupGtmCdpAction() {
  const { $gtm } = useContext()
  return (event) => {
    if (process.client) {
      $gtm.push({
        event,
      })
    }
  }
}

export function setupGtmCdpConcertStatus(concertState, hasLastTicketsStatus) {
  const concertStatusKeys = ['EXPIRED', 'SOLD_OUT', 'MOVED', 'CANCELED']

  if (Object.values(concertState).some((status) => status) || hasLastTicketsStatus) {
    setupGtmCdpEvent('CV07 - Concertstatus available')

    if (hasLastTicketsStatus) {
      setupGtmCdpEvent('CV07 - Concertstatus last tickets')
    }

    for (const statusKey of concertStatusKeys) {
      if (concertState[statusKey]) {
        setupGtmCdpEvent(`CV07 - Concertstatus ${statusKey.toLowerCase()}`)
        break
      }
    }
  }
}
