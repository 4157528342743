
import { computed, defineComponent, ref, useRouter, useStore } from '@nuxtjs/composition-api'
import { mapGetters, mapMutations } from 'vuex'
import get from 'lodash/get'
import { setupTix } from '~/assets/composition/tix'
import { setupGtmNavigationClick } from '~/assets/composition/setup-gtm'
import { setupTranslationPrefixer } from '@/assets/util'
import { explodeUrl } from '@/assets/link'

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const { t_ } = setupTranslationPrefixer('navigation')

    const searching = ref(false)
    const searchInputDesktop = ref(null)

    const toggleSearching = () => {
      searching.value = !searching.value

      setTimeout(() => {
        if (searchInputDesktop.value) {
          searchInputDesktop.value.focus()
        }
      }, 100)

      if (searching.value) {
        document.body.classList.add('overflow-hidden')
        return
      }

      document.body.classList.remove('overflow-hidden')
    }

    const submitSearch = (value) => {
      toggleSearching()
      searching.value = false

      const path = explodeUrl(store.getters['global/search']?.url).path
      router.push({ path, query: { search: value } })
    }

    const favoritesOpenOverlay = () => {
      store.commit('favorites/openOverlayAndStoreId')
    }

    return {
      ...setupTix(),
      get,
      favoritesEnabled: computed(() => store.getters['favorites/enabled']),
      favoritesOpenOverlay,
      navigationClick: setupGtmNavigationClick(),
      searching,
      searchInputDesktop,
      submitSearch,
      toggleSearching,
      t_,
    }
  },
  computed: {
    ...mapGetters({
      menu: 'navigation/menu',
    }),
  },
  methods: {
    ...mapMutations({
      closeAll: 'navigation/closeAll',
      toggleOpen: 'navigation/toggleOpen',
    }),
  },
})
