import debounce from 'lodash/debounce'
import { toHour } from '~/assets/date'

// Provide a single debounce that we can wrap around all fetch calls
const fetchDebounce = debounce((call) => call(), 300)

export const state = () => {
  return {
    // Search context
    keyword: '',
    page: 1,

    // Search results
    events: [],
    loading: true,
    pages: [],
    series: [],
    totalEvents: 0,
    totalPages: 0,
    totalSeries: 0,
  }
}

export const getters = {
  // Search context
  keyword: (state) => state.keyword,
  page: (state) => state.page,

  // Url parameters
  urlParameters: (state) => ({
    keyword: state.keyword || undefined,
    page: state.page === 1 ? undefined : state.page,
  }),

  // Search results
  events: (state) => state.events,
  loading: (state) => state.loading,
  pages: (state) => state.pages,
  series: (state) => state.series,
  totalEvents: (state) => state.totalEvents,
  totalPages: (state) => state.totalPages,
  totalSeries: (state) => state.totalSeries,
}

export const mutations = {
  // Initialize
  initialize: (state, { keyword, page, search }) => {
    // When there are no values given, i.e. from the URL, we will simply keep the most recently used search context
    if (keyword === undefined && page === undefined && search === undefined) {
      return
    }

    state.keyword = typeof keyword === 'string' ? keyword : typeof search === 'string' ? search : '' // For backwards compatability we fallback to the parameter 'search' for the keyword
    state.page = typeof page === 'string' ? Math.max(1, Number.parseInt(page)) : 1
  },

  // Url parameters
  setUrlParameters(state, query) {
    const toValue = (value, fallback) => (value === undefined ? fallback : Array.isArray(value) ? value?.[0] : value)
    state.keyword = toValue(query.keyword, '')
    state.page = Math.max(1, toValue(query.page, 1))
    this.dispatch('search/fetch')
  },

  // Search context
  reset(state) {
    // Search context
    state.keyword = ''
    state.page = 1
    // Search results
    state.events = []
    state.loading = true
    state.pages = []
    state.series = []
    state.totalEvents = 0
    state.totalPages = 0
    state.totalSeries = 0
    setTimeout(() => {
      this.dispatch('search/fetch')
    }, 10)
  },
  setKeyword(state, keyword) {
    state.keyword = keyword
    state.page = 1
    // Debounce because we do not want to fetch on every keystroke
    fetchDebounce(() => this.dispatch('search/fetch'))
  },
  setPage(state, page) {
    state.page = Math.max(1, page)
    this.dispatch('search/fetch')
  },

  // Search results
  setEvents: (state, events) => (state.events = events),
  setLoading: (state, loading) => (state.loading = loading),
  setPages: (state, pages) => (state.pages = pages),
  setSeries: (state, series) => (state.series = series),
  setTotalEvents: (state, totalEvents) => (state.totalEvents = totalEvents),
  setTotalPages: (state, totalPages) => (state.totalPages = totalPages),
  setTotalSeries: (state, totalSeries) => (state.totalSeries = totalSeries),
}

export const actions = {
  async fetch({ commit, dispatch, getters }) {
    const { keyword, page } = getters
    // We actually want to search for events after 'now' and also rounded to the last hour to allow for caching
    const actualDate = toHour('now').toISOString()
    const key = ['search', this.$i18n.locale, `keyword:${keyword}`, `date:${actualDate}`].join(';')
    const ttl = 300

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      // For the topEvents we just retrieve the first few
      const { searchEvents, searchSeries, searchPages } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query search($keyword: String, $site: [String], $eventEndDate: [String], $importIconcertStatus: [String]) {
        searchEvents: elasticSearch(
          section: "event"
          site: $site
          search: $keyword
          fuzzyness: "0"
          eventEndDateRange: $eventEndDate
          orderBy: "eventDate ASC"
          importIconcertStatusFacet: $importIconcertStatus
        ) {
          count
          hits(limit: 3) {
            url
            title
            eventDate
          }
        }

        searchSeries: elasticSearch(section: "series", site: $site, search: $keyword) {
          count
          hits(limit: 3) {
            url
            title
            saleStart: importTixOnlineSaleStart
          }
        }

        searchPages: elasticSearch(
          section: ["page", "campaign", "press", "discovery", "discoveryCollection", "contentOverview"]
          highlight: ["subtitle", "introduction"]
          site: $site
          search: $keyword
        ) {
          count
          hits(limit: 1024) {
            url
            title
            highlights
            introduction
            subtitle
          }
        }
      }`,
        variables: {
          eventEndDate: `gte ${actualDate}`,
          importIconcertStatus: ['1e Optie', 'Bevestigd', 'Contract uit', 'Corona', 'Voor brochure', 'Verplaatst', 'Geannuleerd'],
          keyword,
          site: `${this.$i18n.locale}Default`,
        },
      })

      return {
        events: searchEvents.hits,
        pages: searchPages.hits,
        series: searchSeries.hits,
        totalEvents: searchEvents.count,
        totalPages: searchPages.count,
        totalSeries: searchSeries.count,
      }
    }

    commit('setLoading', true)
    // Fetch the hits
    const { events, pages, series, totalEvents, totalPages, totalSeries } = await dispatch(
      'cache/fetch',
      { key, ttl, fetcher, fallback: { events: [], pages: [], series: [], totalEvents: 0, totalPages: 0, totalSeries: 0 } },
      { root: true }
    )

    commit('setEvents', events)
    commit('setPages', pages.slice((page - 1) * 18, page * 18))
    commit('setSeries', series)
    commit('setTotalEvents', totalEvents)
    commit('setTotalPages', totalPages)
    commit('setTotalSeries', totalSeries)
    commit('setLoading', false)
  },
}
