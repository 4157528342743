function flatten(object, prefix, result) {
  for (const [key, value] of Object.entries(object)) {
    const name = prefix ? `${prefix}.${key}` : key
    if (typeof value === 'object') {
      flatten(value, name, result)
    } else {
      result[name] = value
    }
  }
  return result
}

export const state = () => ({
  discoveryOverview: { title: undefined, url: undefined },
  discoverySearchOverview: { title: undefined, url: undefined },
  errorPages: [],
  eventOverview: { title: undefined, url: undefined },
  favoritePage: { title: undefined, url: undefined },
  pressOverview: { title: undefined, url: undefined },
  search: { title: undefined, url: undefined },
  seriesOverview: { title: undefined, url: undefined },
  site: { handle: undefined, language: 'nl', name: undefined },
  translations: {},
  vriendenloterij: { introduction: undefined },
})

export const getters = {
  discoveryOverview: (state) => state.discoveryOverview,
  discoverySearchOverview: (state) => state.discoverySearchOverview,
  errorPages: (state) => state.errorPages,
  eventOverview: (state) => state.eventOverview,
  favoritePage: (state) => state.favoritePage,
  pressOverview: (state) => state.pressOverview,
  search: (state) => state.search,
  seriesOverview: (state) => state.seriesOverview,
  site: (state) => state.site,
  translations: (state) => state.translations,
  vriendenloterij: (state) => state.vriendenloterij,
}

export const mutations = {
  set: (
    state,
    { discoveryOverview, discoverySearchOverview, errorPages, eventOverview, favoritePage, pressOverview, search, seriesOverview, site, translations, vriendenloterij }
  ) => {
    state.discoveryOverview = discoveryOverview
    state.discoverySearchOverview = discoverySearchOverview
    state.errorPages = errorPages
    state.eventOverview = eventOverview
    state.favoritePage = favoritePage
    state.pressOverview = pressOverview
    state.search = search
    state.seriesOverview = seriesOverview
    state.site = site
    state.translations = translations
    state.vriendenloterij = vriendenloterij
  },
}

export const actions = {
  async fetch({ commit, dispatch }, { locale }) {
    const key = `global;locale:${locale}`
    const ttl = 3600

    const fetcher = async () => {
      // Unable to use "this.$cmsFetch" here because this plugin is not yet available when i18n calls this store action through fetchTranslations
      const response = await (await fetch(`${this.$config.cmsSiteUrl}/api/default/${locale}/global.json`)).json()
      // Merge the static translations from disk with the dynamic translations from the database
      response.translations = {
        ...flatten(require(`~/assets/translations/${locale}.json`), '', {}),
        ...Object.fromEntries(response.translations.map(({ key, value }) => [key, value])),
      }
      return response
    }

    const data = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    if (data) {
      commit('set', data)
    }
    return data
  },

  async fetchTranslations({ dispatch }, { locale }) {
    // Value "this.$i18n.locale" is not available, as this is called in config of i18n
    const data = await dispatch('fetch', { locale })
    return data?.translations || {}
  },

  async fetchNavigation({ commit, dispatch }) {
    const data = await dispatch('fetch', { locale: this.$i18n.locale })
    commit('navigation/setNavigation', data.navigation, { root: true })
    return data?.navigation
  },

  async fetchSeo({ dispatch }) {
    const data = await dispatch('fetch', { locale: this.$i18n.locale })
    return data?.seo
  },

  async fetchErrorPage({ dispatch }, { statusCode }) {
    const data = await dispatch('fetch', { locale: this.$i18n.locale })
    // Return the page with the correct statusCode
    for (const page of data.errorPages) {
      if (page.statusCode === statusCode) {
        return page
      }
    }
    // Fallback to the fallback page
    for (const page of data.errorPages) {
      if (page.isFallback) {
        return page
      }
    }
  },
}
