// https://vueformulate.com/guide/installation/#nuxt

import { en, nl } from '@braid/vue-formulate-i18n'

export default {
  plugins: [en, nl],
  slotComponents: { label: 'AtomFormulateLabel' },
  slotProps: { label: ['labelIsHtml'] },
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
}
