
import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    name: { type: String, required: true },
    visible: { type: Boolean, required: true },
    backgroundColor: { type: String, required: false, default: 'purple' },
  },
  watch: {
    visible(newVal) {
      newVal ? this.$modal.show(this.name) : this.$modal.hide(this.name)
    },
  },
  methods: {
    computeModalWidth() {
      let modalWidth = '75%'
      switch (this.$mq) {
        case 'sm':
          modalWidth = '95%'
          break
        case 'md':
          modalWidth = '90%'
          break
        case 'lg':
          modalWidth = '85%'
          break
        case 'xl':
          modalWidth = '75%'
          break
        default:
          modalWidth = '75%'
      }
      return modalWidth
    },
  },
})
