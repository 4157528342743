import xor from 'lodash/xor'

export const state = () => ({
  eventIConcertIds: [], // Favorite event ids
  eventUris: [], // Favorite event uris (populated from the bridge)
  user: undefined, // Logged in user information
  overlayIsOpen: false,
})

export const getters = {
  enabled: (state) => !!(state.user?.id && state.user?.hash),
  eventIConcertIds: (state) => state.eventIConcertIds,
  eventUris: (state) => state.eventUris,
  overlayIsOpen: (state) => state.overlayIsOpen,
  debug: (state) => {
    return state.user
  },
}

export const mutations = {
  reset(state) {
    state.eventIConcertIds = []
    state.eventUris = []
  },
  toggleEventIConcertId(state, eventIConcertId) {
    state.eventIConcertIds = xor(state.eventIConcertIds, [eventIConcertId])
    this.dispatch('favorites/save', { user: state.user, eventIConcertIds: state.eventIConcertIds })
  },
  openOverlayAndStoreId(state, eventIConcertId) {
    state.overlayIsOpen = true
    if (eventIConcertId) {
      window.sessionStorage.setItem('eventIConcertId', eventIConcertId)
    }
  },
  closeOverlay(state) {
    state.overlayIsOpen = false
    window.sessionStorage.removeItem('eventIConcertId')
  },
  setUser(state, user) {
    state.user = user
    this.dispatch('favorites/load', { user: state.user })
  },
  updateFromBridge(state, { eventIConcertIds, eventUris }) {
    state.eventIConcertIds = eventIConcertIds
    state.eventUris = eventUris
  },
  updateFromSession(state, eventIConcertId) {
    if (eventIConcertId && !state.eventIConcertIds.includes(eventIConcertId)) {
      state.eventIConcertIds = xor(state.eventIConcertIds, [eventIConcertId])
      this.dispatch('favorites/save', { user: state.user, eventIConcertIds: state.eventIConcertIds })
    }
  },
}

export const actions = {
  async load({ commit }, { user }) {
    if (user?.id && user?.hash) {
      commit('updateFromBridge', await this.$bridgeFetch(`/favorites/v1/${this.$i18n.locale}/${user.id}/load.json?hash=${user.hash}`))

      const storedIConcertId = sessionStorage.getItem('eventIConcertId') ? Number(sessionStorage.getItem('eventIConcertId')) : undefined
      if (storedIConcertId) {
        commit('updateFromSession', storedIConcertId)
        window.sessionStorage.removeItem('eventIConcertId')
      }
    } else {
      commit('reset')
    }
  },
  async save({ commit }, { user, eventIConcertIds }) {
    if (user?.id && user?.hash) {
      commit(
        'updateFromBridge',
        await this.$bridgeFetch(`/favorites/v1/${this.$i18n.locale}/${user.id}/save.json?hash=${user.hash}`, {
          method: 'PUT',
          headers: { Accept: 'application/json' },
          body: JSON.stringify({ eventIConcertIds }),
        })
      )
    } else {
      commit('reset')
    }
  },
}
