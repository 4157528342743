import { defineNuxtPlugin } from '@nuxtjs/composition-api'

// todo: this should be removed in favor of `<SmoothReflow>...</SmoothReflow>`

export default defineNuxtPlugin((context, inject) => {
  inject('autoHeightFlow', (dom) => {
    if (dom.style.maxHeight === '0px' || dom.style.maxHeight === '') {
      dom.style.transition = '0.25s ease-in-out all'
      dom.style.maxHeight = '9999px'

      setTimeout(() => {
        const contentHeight = dom.offsetHeight
        dom.style.maxHeight = contentHeight + 'px'
      }, 300)

      return
    }

    dom.style.maxHeight = '0'
  })
})
