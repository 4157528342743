/**
 * This directive will execute a function of your choice when you click outside the element
 * Add the directive on any element you want i.e:
 *
 * <AtomButton v-closable="{ executable: close, exclude: '.ignore-close' }" />
 *
 * In the button component itself, make sure you have a function called "close" as in this example.
 *
 * The function "close" will be called upon when the requirements are met. Meaning you can use any
 * function from the component you use the directive on without any restrictions.
 *
 * You can add a selector, if the target element.closest(selector) returns an element, the executable is not called.
 */

import Vue from 'vue'

let handleOutsideClick

Vue.directive('closable', {
  bind(el, binding, vnode) {
    const executable = binding.value.executable
    const excludeSelector = binding.value.exclude

    handleOutsideClick = (e) => {
      // Check that the click happened outside the v-closable element
      if (el.contains(e.target)) {
        // console.debug('closable: ignoring click because element contains the target ') // Please leave this debug comment for debugging
        return
      }

      // Check that where we clicked does not have a parent that is excluded
      if (excludeSelector && e.target.closest(excludeSelector)) {
        // console.debug('closable: ignoring click because the target has an excluded parent') // Please leave this debug comment for debugging
        return
      }

      // execute code here that should fire, code should be in the component itself
      if (typeof executable === 'function') {
        // console.debug('closable: executing closing function') // Please leave this debug comment for debugging
        executable()
      } else if (typeof executable === 'string' && vnode.context[executable]) {
        // console.debug('closable: executing closing string within component context') // Please leave this debug comment for debugging
        vnode.context[executable]()
      } else {
        // console.debug('closable: unable to call closing executable') // Please leave this debug comment for debugging
      }
    }

    // Add the event handler the will be temporarily available
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind() {
    // when the element is removed, unbind it to safe performance
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  },
})
