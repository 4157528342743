
import { watch, defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { linkDetails } from '~/assets/link'
import { getLinkRewrites } from '~/assets/craft'

const validTargets = ['_self', '_blank', '_parent', '_top']

export default defineComponent({
  props: {
    url: { type: [Object, String], required: false, default: undefined },
    rel: { type: String, required: false, default: undefined },
    target: { type: String, required: false, default: undefined, validator: (value) => validTargets.includes(value) },
    fallbackComponent: { type: String, required: false, default: 'span' },
  },
  setup(props, { emit }) {
    const { $config, localePath, req } = useContext()
    const rewrites = getLinkRewrites($config)
    const currentHost = `//${req?.headers.host || window?.location.host}`
    const component = ref('span')
    const fallbackTarget = ref(undefined)
    const href = ref(undefined)
    const relAdditional = ref(undefined)
    const to = ref(undefined)

    const setter = (values) => {
      component.value = values.component
      fallbackTarget.value = values.fallbackTarget
      href.value = values.href
      relAdditional.value = values.relAdditional
      to.value = values.to
    }

    const update = (target) => {
      if (target && typeof target === 'object') {
        target = localePath(target)
      }

      if (!target) {
        return setter({ component: props.fallbackComponent })
      }

      const { distance, scope, url, type } = linkDetails(currentHost, target, { rewrites })

      switch (scope) {
        // Use NuxtLink because this is inside the app
        case 'inside':
          return type === 'anchor'
            ? // When this is only an anchor, i.e. #foo-bar, using NuxtLink would disable the anchor behavior when that anchor is already in the url
              setter({ component: 'a', fallbackTarget: '_self', href: url })
            : // Other types can be NuxtLink
              setter({ component: 'NuxtLink', href: url, to: url })

        // Fallback to old-school <a href=...>
        case 'outside':
        default:
          return setter(
            distance === -1
              ? // Going to a completely different domain, i.e. from zicht.nl to example.com
                { component: 'a', fallbackTarget: '_blank', href: url, relAdditional: 'noopener' }
              : // Going to a subdomain of the same site, i.e. from zicht.nl to jobs.zicht.nl
                { component: 'a', href: url }
          )
      }
    }

    // Immediately set the link
    update(props.url)

    // Update the link whenever the url changes
    watch(props, () => update(props.url))

    const onClick = (event) => emit('click', event)

    return {
      component,
      fallbackTarget,
      href,
      onClick,
      relAdditional,
      to,
    }
  },
})
