// note that "discoveryCollections" is renamed "categories" in this store for SearchOverview page
// note that "discoveryTags" is renamed "tags" in this store for SearchOverview page
import debounce from 'lodash/debounce'

// Provide a single debounce that we can wrap around all fetch calls
const fetchDebounce = debounce((call) => call(), 300)

export const state = () => {
  return {
    // Global results (i.e. unaffected by search context)
    allFacets: {},

    // Search context
    keyword: '',
    categories: [],
    tags: [],
    page: 1,

    // Search results
    discoveries: [], // our main data source
    facets: {},
    hits: [],
    loading: true,
    totalHits: 0,
  }
}

export const getters = {
  // Constants
  hitsPerPage: () => 15,

  // Search context
  keyword: (state) => state.keyword,
  page: (state) => state.page,
  categories: (state) => state.categories,
  tags: (state) => state.tags,

  urlParameters: (state) => ({
    keyword: state.keyword || undefined,
    page: state.page === 1 ? undefined : state.page,
    categories: state.categories,
    tags: state.tags,
  }),
  facets: (state) => {
    const { allFacets, facets, categories } = state
    const helper = (fromAllFacets, fromFacets, activeList) => {
      const currentCountMap = Object.fromEntries((fromFacets || []).map(({ count, label, value }) => [value || label, count]))
      return (fromAllFacets || []).map(({ value, label }) => ({
        active: activeList.includes(value || label),
        count: currentCountMap[value || label] || 0,
        label,
        value: value || label,
      }))
    }
    return [{ key: 'categories', values: helper(allFacets.categories, facets.categories, categories) }]
  },
  activeTagFacets: (state) => {
    const { tags } = state
    return [
      // 'tags' are special as we do not display them as facets to the user, but they must be removable
      ...tags.map((value) => ({ facet: 'tags', label: value, value })),
    ]
  },
  activeRegularFacets: (state) => {
    const { allFacets, categories } = state
    const helper = (fromAllFacets, activeList, facet) =>
      (fromAllFacets || []).filter(({ value, label }) => activeList.includes(value || label)).map(({ value, label }) => ({ facet, label, value: value || label }))
    return [...helper(allFacets.categories, categories, 'categories')]
  },
  discoveries: (state) => state.discoveries,
  hits: (state) => state.hits,
  loading: (state) => state.loading,
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Global results (i.e. unaffected by search context)
  setAllFacets: (state, allFacets) => (state.allFacets = allFacets),

  // Initialize
  initialize: (state, { keyword, page, categories, tags }) => {
    // When there are no values given, i.e. from the URL, we will simply keep the most recently used search context
    if (keyword === undefined && page === undefined && categories === undefined && tags === undefined) {
      return
    }

    const toArray = (value) => (value === undefined ? [] : Array.isArray(value) ? value : [value])

    // For backwards compatability we fallback to the parameter 'search' for the keyword
    state.keyword = typeof keyword === 'string' ? keyword : ''
    state.page = typeof page === 'string' ? Math.max(1, Number.parseInt(page)) : 1
    state.categories = toArray(categories)
    state.tags = toArray(tags)
  },

  // Url parameters
  setUrlParameters(state, query) {
    const toArray = (value) => (value === undefined ? [] : Array.isArray(value) ? value : [value])
    const toValue = (value, fallback) => (value === undefined ? fallback : Array.isArray(value) ? value?.[0] : value)
    state.keyword = toValue(query.keyword, '')
    state.categories = toArray(query.categories)
    state.tags = toArray(query.tags)
    state.page = Math.max(1, toValue(query.page, 1))
    this.dispatch('discovery-overview/fetch')
  },
  reset(state) {
    state.page = 1
    state.categories = []
    state.keyword = ''
    // note that we do NOT reset the state.tags because this is not a facet that can be set by the user
    this.dispatch('discovery-overview/fetch')
  },
  // Search context
  setKeyword(state, keyword) {
    state.keyword = keyword
    state.page = 1
    // Debounce because we do not want to fetch on every keystroke
    fetchDebounce(() => this.dispatch('discovery-overview/fetch'))
  },
  setPage(state, page) {
    state.page = Math.max(1, page)
    this.dispatch('discovery-overview/fetch')
  },
  // only enable one type of facet value
  enableFacet(state, { name, value }) {
    switch (name) {
      case 'categories':
        state.categories = [value]
        state.page = 1
        this.dispatch('discovery-overview/fetch')
        break
      case 'tags':
        state.tags = [value]
        state.page = 1
        this.dispatch('discovery-overview/fetch')
        break
      default:
        // eslint-disable-next-line no-console
        console.error(`Unable to enable unknown facet "${name}" with value "${value}"`)
        break
    }
  },
  // reset facet by name, as only one facet can be active
  resetFacet(state, { name }) {
    switch (name) {
      case 'categories':
        state.categories = []
        state.page = 1
        this.dispatch('discovery-overview/fetch')
        break
      case 'tags':
        state.tags = []
        state.page = 1
        this.dispatch('discovery-overview/fetch')
        break
      default:
        // eslint-disable-next-line no-console
        console.error(`Unable to remove unknown facet "${name}"`)
        break
    }
  },
  // Search results
  setFacets: (state, facets) => (state.facets = facets),
  setHits: (state, hits) => (state.hits = hits),
  setTotalHits: (state, totalHits) => (state.totalHits = totalHits),
  setDiscoveries: (state, discoveries) => (state.discoveries = discoveries),
  setLoading: (state, loading) => (state.loading = loading),
}

export const actions = {
  async fetchAllFacets({ commit, dispatch }) {
    const key = `discovery-facets;${this.$i18n.locale};all-facets`
    const ttl = 900

    const fetcher = async () => {
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query allDiscoveryFacets($site:[String]) {
            elasticSearch(site:$site, section:"discovery") {
              facets(limit:1024) {
                categories: discoveryCollections { label: value }
              }
            }
          }`,
        variables: { site: `${this.$i18n.locale}Default` },
      })
      return elasticSearch.facets
    }

    // Fetch the hits
    const allFacets = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: {} }, { root: true })
    commit('setAllFacets', allFacets)
    return allFacets
  },

  async fetch({ commit, dispatch, getters }) {
    const { hitsPerPage, keyword, page, categories, tags } = getters
    const orderBy = [keyword === '' ? 'postDate DESC' : '']

    const key = ['discoveries', this.$i18n.locale, `keyword:${keyword}`, `categories:${categories.join(',')}`, `tags:${tags.join(',')}`, `orderBy:${orderBy}`].join(';')
    const ttl = 300

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query searchDiscoveries($site: [String], $keyword: String, $discoveryCollections:[String], $discoveryTags:[String], $orderBy:[String]) {
          elasticSearch(site: $site, section: "discovery", search: $keyword, discoveryCollectionsFacet:$discoveryCollections, discoveryTagsFacet:$discoveryTags, orderBy:$orderBy) {
            facets(limit: 1024) {
              categories: discoveryCollections {
                label: value
                count
              }
            }
            count
            hits(limit: 1024) {
              uri
            }
          }
        }
        `,
        variables: {
          site: `${this.$i18n.locale}Default`,
          keyword,
          discoveryCollections: categories,
          discoveryTags: tags,
          orderBy,
        },
      })

      return { facets: elasticSearch.facets, hits: elasticSearch.hits.map(({ uri }) => uri), totalHits: elasticSearch.count }
    }

    // Fetch the hits
    const { facets, hits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { facets: {}, hits: [], totalHits: 0 } }, { root: true })
    commit('setFacets', facets)
    commit('setHits', hits)
    commit('setTotalHits', totalHits)

    // todo: add loaders
    // Initialize the series list with N empty elements, used to trigger loaders
    const slice = hits.slice((page - 1) * hitsPerPage, page * hitsPerPage)

    // Fetch all associated entries within the current page
    const discoveries = await Promise.all(slice.map((uri) => dispatch('page/fetchUri', { uri }, { root: true })))
    commit(
      'setDiscoveries',
      discoveries.filter((discovery) => discovery)
    )
    commit('setLoading', false)
    return discoveries
  },
}
