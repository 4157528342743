
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    child: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
