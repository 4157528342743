import debounce from 'lodash/debounce'
import xor from 'lodash/xor'
import { toDay, toHour } from '~/assets/date'

// Provide a single debounce that we can wrap around all fetch calls
const fetchDebounce = debounce((call) => call(), 300)

export const state = () => {
  return {
    // Global results (i.e. unaffected by search context)
    allFacets: {},

    // Search context
    date: toDay('today').toISOString(),
    genres: [],
    keyword: '',
    organizers: [],
    page: 1,
    rooms: [],

    // Highlighted organizer (only set when a single organizer facet is active)
    highlightedOrganizer: undefined,

    // Banner results
    fallbackBanners: [],
    banners: [],

    // Search results
    series: [],
    facets: {},
    hits: [],
    loading: true,
    totalHits: 0,
  }
}

export const getters = {
  // Constants
  hitsPerPage: () => 15,

  // Search context
  date: (state) => state.date,
  genres: (state) => state.genres,
  keyword: (state) => state.keyword,
  organizers: (state) => state.organizers,
  page: (state) => state.page,
  rooms: (state) => state.rooms,

  // Url parameters
  urlParameters: (state) => ({
    genres: state.genres,
    keyword: state.keyword || undefined,
    organizers: state.organizers,
    page: state.page === 1 ? undefined : state.page,
    rooms: state.rooms,
  }),

  // Banner results
  bannersAtPosition: (state) => {
    const { fallbackBanners, banners } = state
    return {
      // First the fallback banners
      ...Object.fromEntries(fallbackBanners.map((banner) => [banner.seriesPosition, banner])),
      // Second the banners based on search criteria, these will overwrite the fallback banners
      ...Object.fromEntries(banners.map((banner) => [banner.seriesPosition, banner])),
    }
  },

  // Highlighted organizer (only set when a single organizer facet is active)
  highlightedOrganizer: (state) => state.highlightedOrganizer,

  // Search results
  facets: (state) => {
    const { allFacets, facets, genres, organizers, rooms } = state
    const helper = (fromAllFacets, fromFacets, activeList) => {
      const currentCountMap = Object.fromEntries((fromFacets || []).map(({ count, label, value }) => [value || label, count]))
      return (fromAllFacets || []).map(({ value, label }) => ({
        active: activeList.includes(value || label),
        count: currentCountMap[value || label] || 0,
        label,
        value: value || label,
      }))
    }
    return [
      { key: 'organizers', values: helper(allFacets.organizers, facets.organizers, organizers) },
      { key: 'genres', values: helper(allFacets.genres, facets.genres, genres) },
      { key: 'rooms', values: helper(allFacets.rooms, facets.rooms, rooms) },
    ]
  },
  activeFacets: (state) => {
    const { allFacets, genres, organizers, rooms } = state
    const helper = (fromAllFacets, activeList, facet) =>
      (fromAllFacets || []).filter(({ value, label }) => activeList.includes(value || label)).map(({ value, label }) => ({ facet, label, value: value || label }))
    return [...helper(allFacets.genres, genres, 'genres'), ...helper(allFacets.organizers, organizers, 'organizers'), ...helper(allFacets.rooms, rooms, 'rooms')]
  },
  series: (state) => state.series,
  hits: (state) => state.hits,
  loading: (state) => state.loading,
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Global results (i.e. unaffected by search context)
  setAllFacets: (state, allFacets) => (state.allFacets = allFacets),

  // Initialize
  initialize: (
    state,
    {
      genres,
      organizers,
      page,
      rooms,
      keyword,
      // 'organizers' and 'rooms' were previously available using different parameters.
      // We take this into account for backwards compatability.
      organizer: backwardsCompatibleOrganizers,
      room: backwardCompatibleRooms,
    }
  ) => {
    // When there are no values given, i.e. from the URL, we will simply keep the most recently used search context
    if (
      genres === undefined &&
      organizers === undefined &&
      page === undefined &&
      rooms === undefined &&
      keyword === undefined &&
      backwardsCompatibleOrganizers === undefined &&
      backwardCompatibleRooms === undefined
    ) {
      return
    }

    const toArray = (value) => (value === undefined ? [] : Array.isArray(value) ? value : [value])
    const toBackwardCompatibleArray = (value, backwardCompatibleValue) => (value === undefined ? toArray(backwardCompatibleValue) : toArray(value))
    state.genres = toArray(genres)
    state.keyword = typeof keyword === 'string' ? keyword : ''
    state.organizers = toBackwardCompatibleArray(organizers, backwardsCompatibleOrganizers)
    state.page = typeof page === 'string' ? Math.max(1, Number.parseInt(page)) : 1
    state.rooms = toBackwardCompatibleArray(rooms, backwardCompatibleRooms)
  },

  // Highlighted organizer (only set when a single organizer facet is active)
  setHighlightedOrganizer: (state, highlightedOrganizer) => (state.highlightedOrganizer = highlightedOrganizer),

  // Search context
  reset(state) {
    state.genres = []
    state.keyword = ''
    state.organizers = []
    state.page = 1
    state.rooms = []
    this.dispatch('series-overview/fetchHighlightedOrganizer')
    this.dispatch('series-overview/fetch')
    this.dispatch('series-overview/fetchBanners')
  },
  setKeyword(state, keyword) {
    state.keyword = keyword
    state.page = 1
    // Debounce because we do not want to fetch on every keystroke
    fetchDebounce(() => this.dispatch('series-overview/fetch'))
  },
  setPage(state, page) {
    state.page = Math.max(1, page)
    this.dispatch('series-overview/fetch')
  },
  toggleFacet(state, { name, value }) {
    switch (name) {
      case 'genres':
        state.genres = xor(state.genres, [value])
        state.page = 1
        this.dispatch('series-overview/fetch')
        this.dispatch('series-overview/fetchBanners')
        break
      case 'organizers':
        state.organizers = xor(state.organizers, [value])
        state.page = 1
        this.dispatch('series-overview/fetchHighlightedOrganizer')
        this.dispatch('series-overview/fetch')
        this.dispatch('series-overview/fetchBanners')
        break
      case 'rooms':
        state.rooms = xor(state.rooms, [value])
        state.page = 1
        this.dispatch('series-overview/fetch')
        this.dispatch('series-overview/fetchBanners')
        break
      default:
        // eslint-disable-next-line no-console
        console.error(`Unable to toggle unknown facet "${name}" with value "${value}"`)
        break
    }
  },

  // Banner results
  setFallbackBanners: (state, fallbackBanners) => (state.fallbackBanners = fallbackBanners),
  setBanners: (state, banners) => (state.banners = banners),

  // Search results
  setFacets: (state, facets) => (state.facets = facets),
  setHits: (state, hits) => (state.hits = hits),
  setLoading: (state, loading) => (state.loading = loading),
  setSeries: (state, series) => (state.series = series),
  setTotalHits: (state, totalHits) => (state.totalHits = totalHits),
}

export const actions = {
  async fetchFallbackBanners({ commit, dispatch }) {
    const key = `series-fallback-banners;${this.$i18n.locale}`
    const ttl = 3600

    const fetcher = async () => {
      return (await this.$cmsFetch(`/api/${this.$i18n.locale}/series/fallback-banners.json`)).data
    }

    const fallbackBanners = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: [] }, { root: true })
    commit('setFallbackBanners', fallbackBanners)
    return fallbackBanners
  },

  async fetchBanners({ commit, dispatch, getters }) {
    const { organizers, genres, rooms } = getters
    const key = `series-banners;${this.$i18n.locale};organizers:${organizers.join(',')};genres:${genres.join(',')};rooms:${rooms.join(',')}`
    const ttl = 3600

    // We only support showing special banners when only one of the facets is used
    let banners = []
    const sections = [organizers.length > 0 ? 'organizer' : undefined, genres.length > 0 ? 'genre' : undefined, rooms.length > 0 ? 'room' : undefined].filter((section) => section)

    if (sections.length === 1) {
      const fetcher = async () => {
        return (
          await this.$cmsFetch(
            `/api/${this.$i18n.locale}/banners/series/${sections[0]}/${[...organizers, ...genres, ...rooms]
              .map((value) => value.replace('/', '-SLASH-')) // There can be no slashes in the uri
              .join(',')}.json`
          )
        ).data
      }
      banners = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: [] }, { root: true })
    }

    commit('setBanners', banners)
    return banners
  },
  async fetchHighlightedOrganizer({ commit, dispatch, getters }) {
    const { organizers } = getters

    if (organizers.length !== 1) {
      commit('setHighlightedOrganizer', undefined)
      return
    }

    const key = `organizer;${this.$i18n.locale};${organizers[0]}`
    const ttl = 3600

    const fetcher = async () => {
      const organizer = await this.$cmsFetch(`/api/${this.$i18n.locale}/organizer/${encodeURIComponent(organizers[0])}.json`)
      // We want to ignore organizers that only have no additional information
      return organizer.image || organizer.introduction || organizer.subtitle ? organizer : undefined
    }

    const organizer = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    commit('setHighlightedOrganizer', organizer)
    return organizer
  },

  async fetchAllFacets({ commit, dispatch }) {
    const key = `series-overview;${this.$i18n.locale};all-facets`
    const ttl = 900

    const fetcher = async () => {
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query allEventFacets($site:[String]) {
            elasticSearch(site:$site, section:"series") {
              facets(limit:1024) {
                genres { label: value }
                organizers: organizer { label: value }
                rooms: room { label: value }
              }
            }
          }`,
        variables: { site: `${this.$i18n.locale}Default` },
      })
      return elasticSearch.facets
    }

    // Fetch the hits
    const allFacets = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    commit('setAllFacets', allFacets)
    return allFacets
  },

  async fetch({ commit, dispatch, getters }) {
    const { genres, hitsPerPage, organizers, keyword, page, rooms, date } = getters
    const actualDate = toHour(date).toISOString()
    const key = [
      'series-overview',
      this.$i18n.locale,
      `keyword:${keyword}`,
      `date:${actualDate}`,
      `genres:${genres.join(',')}`,
      `organizers:${organizers.join(',')}`,
      `rooms:${rooms.join(',')}`,
    ].join(';')
    const ttl = 300

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query searchEvents($site:[String], $keyword:String, $onlineSaleEnd: [String], $genres:[String], $organizers: [String], $rooms:[String]) {
            elasticSearch(site:$site, section:"series", search:$keyword, genresFacet:$genres, organizerFacet:$organizers, roomFacet:$rooms, importTixOnlineSaleEndRange: $onlineSaleEnd, orderBy: "eventDate") {
              facets(limit:1024) {
                genres { label: value, count }
                organizers: organizer { label: value, count }
                rooms: room { label: value, count }
              }
              count
              hits(limit:1024) {
               uri
              }
            }
          }`,
        variables: {
          site: `${this.$i18n.locale}Default`,
          onlineSaleEnd: `gte ${actualDate}`, // only show series with OnlineSaleEnd > now
          keyword,
          genres,
          organizers,
          rooms,
        },
      })

      return { facets: elasticSearch.facets, hits: elasticSearch.hits.map(({ uri }) => uri), totalHits: elasticSearch.count }
    }

    // Fetch the hits
    const { facets, hits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    commit('setFacets', facets)
    commit('setHits', hits)
    commit('setTotalHits', totalHits)

    // Initialize the series list with N empty elements, used to trigger loaders
    const slice = hits.slice((page - 1) * hitsPerPage, page * hitsPerPage)
    commit(
      'setSeries',
      slice.map((uri) => ({ loading: true, uri }))
    )

    // Fetch all associated entries within the current page
    const series = await Promise.all(slice.map((uri) => dispatch('page/fetchUri', { uri }, { root: true })))

    // Sort series by amount of events.
    series.sort((a, b) => {
      const firstEventDateA = new Date(a.events[0].eventDate.substring(0, 10))
      const firstEventDateB = new Date(b.events[0].eventDate.substring(0, 10))

      if (firstEventDateA < firstEventDateB) return -1
      if (firstEventDateA > firstEventDateB) return 1

      // If first event dates are the same, sort by the number of events
      return b.events.length - a.events.length
    })

    commit('setSeries', series)
    commit('setLoading', false)
    return hits
  },
}
