import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=20daa045"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomLink: require('/home/userapp/components/Atom/Link.vue').default,AtomIcon: require('/home/userapp/components/Atom/Icon.vue').default,AtomIconMail: require('/home/userapp/components/Atom/Icon/IconMail.vue').default,MoleculeButton: require('/home/userapp/components/Molecule/Button/Button.vue').default,AtomLogosHetConcertgebouwFonds: require('/home/userapp/components/Atom/Logos/LogosHetConcertgebouwFonds.vue').default,AtomLogosVanLanschot: require('/home/userapp/components/Atom/Logos/LogosVanLanschot.vue').default})
