import { render, staticRenderFns } from "./ControlPanel.vue?vue&type=template&id=e2bf45ec"
import script from "./ControlPanel.vue?vue&type=script&lang=js"
export * from "./ControlPanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomIconPencil: require('/home/userapp/components/Atom/Icon/IconPencil.vue').default,AtomLink: require('/home/userapp/components/Atom/Link.vue').default,OrganismControlPanelFoldout: require('/home/userapp/components/Organism/ControlPanel/ControlPanelFoldout.vue').default,AtomIconTranslate: require('/home/userapp/components/Atom/Icon/IconTranslate.vue').default,AtomIconPowerSwitch: require('/home/userapp/components/Atom/Icon/IconPowerSwitch.vue').default})
