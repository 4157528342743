
import { defineComponent } from '@nuxtjs/composition-api'

const statusCodeMap = { 404: 'Sorry, de pagina is niet beschikbaar', 500: 'Er is helaas iets misgegaan' }

export default defineComponent({
  layout: 'error',
  props: {
    error: { type: Object, required: false, default: undefined },
  },
  data: () => ({
    description: '',
    image: undefined,
    introduction: '',
    subtitle: '',
    title: statusCodeMap[500],
  }),
  async fetch() {
    const page = await this.$store.dispatch('global/fetchErrorPage', { statusCode: this.error?.statusCode || 500 })
    if (page) {
      this.description = page.description
      this.image = page.image
      this.introduction = page.introduction
      this.subtitle = page.subtitle
      this.title = page.title
    }
  },
})
