import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _25dafb54 = () => interopDefault(import('../pages/app-health-check.vue' /* webpackChunkName: "pages/app-health-check" */))
const _70fcce13 = () => interopDefault(import('../pages/debug/index.vue' /* webpackChunkName: "pages/debug/index" */))
const _535f22e2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a7e1a1fc = () => interopDefault(import('../pages/debug/buttons.vue' /* webpackChunkName: "pages/debug/buttons" */))
const _9d3a6ed0 = () => interopDefault(import('../pages/debug/favorites.vue' /* webpackChunkName: "pages/debug/favorites" */))
const _c236de6e = () => interopDefault(import('../pages/debug/link.vue' /* webpackChunkName: "pages/debug/link" */))
const _64889d38 = () => interopDefault(import('../pages/education-user-edit.vue' /* webpackChunkName: "pages/education-user-edit" */))
const _c13f4540 = () => interopDefault(import('../pages/education/_slug.vue' /* webpackChunkName: "pages/education/_slug" */))
const _07bfc28c = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _e87adbc8 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app-health-check",
    component: _25dafb54,
    name: "app-health-check"
  }, {
    path: "/debug",
    component: _70fcce13,
    name: "debug___nl"
  }, {
    path: "/en",
    component: _535f22e2,
    name: "index___en"
  }, {
    path: "/debug/buttons",
    component: _a7e1a1fc,
    name: "debug-buttons___nl"
  }, {
    path: "/debug/favorites",
    component: _9d3a6ed0,
    name: "debug-favorites___nl"
  }, {
    path: "/debug/link",
    component: _c236de6e,
    name: "debug-link___nl"
  }, {
    path: "/en/debug",
    component: _70fcce13,
    name: "debug___en"
  }, {
    path: "/en/remove-from-route--61586b0c04b3878de7bebccea5977e03c6725033",
    component: _64889d38,
    name: "education-user-edit___en"
  }, {
    path: "/en/remove-from-route--926bb34a4259a5030823522d31e106fa",
    component: _c13f4540,
    name: "education-slug___en"
  }, {
    path: "/educatie/gebruiker/aanpassen",
    component: _64889d38,
    name: "education-user-edit___nl"
  }, {
    path: "/en/debug/buttons",
    component: _a7e1a1fc,
    name: "debug-buttons___en"
  }, {
    path: "/en/debug/favorites",
    component: _9d3a6ed0,
    name: "debug-favorites___en"
  }, {
    path: "/en/debug/link",
    component: _c236de6e,
    name: "debug-link___en"
  }, {
    path: "/",
    component: _535f22e2,
    name: "index___nl"
  }, {
    path: "/en/admin/(.*)?",
    component: _07bfc28c,
    name: "admin___en"
  }, {
    path: "/admin/(.*)?",
    component: _07bfc28c,
    name: "admin___nl"
  }, {
    path: "/education-redirect/:eventGroupId/event/:eventId/educatie/projecten/:slug",
    component: _c13f4540,
    name: "education-slug___nl"
  }, {
    path: "/en/*",
    component: _e87adbc8,
    name: "all___en"
  }, {
    path: "/*",
    component: _e87adbc8,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
