import kebabCase from 'lodash/kebabCase'

export function getLinkRewrites($config) {
  return [
    [
      // The first host is the target for the rewrites in the list, it is environment dependent
      $config?.wwwSiteUrl.match(/^(?<protocol>[^:]+:)?(\/\/(?<host>[^/]+))?(?<rest>.*$)/i).groups.host,
      'concertgebouw.nl',
      'www.concertgebouw.nl',
      'a.concertgebouw.nl',
      'www.a.concertgebouw.nl',
      'test',
      'dev.concertgebouw.nl',
    ],
  ]
}

/**
 * Returns a breadcrumb array based on the structure returned by `pathQuery` from `queries.js`
 */
export function setupBreadcrumbs(page, prefixes = []) {
  return {
    breadcrumbs: [
      // The 'home' is always included, taking into account the current subsite
      { labelKey: 'home', url: { name: 'index' } },
      // Optional prefixes can be included, i.e. back to an overview page
      ...prefixes,
      // Optional paths configured in CraftCMS
      ...(Array.isArray(page?.path)
        ? // (provided by the new REST API)
          page.path
        : // (provided by the old GraphQL API)
          [page?.path?.parent?.parent, page?.path?.parent, page?.path]),
      // The page itself
      { label: page?.title, url: page?.url },
    ]
      // Ensure every crumb is valid, i.e. has a url and either a label or a labelKey
      .filter((crumb) => crumb?.url && (crumb?.label || crumb?.labelKey))
      // Prepare any labelKey props into labelTemplate props
      .map((crumb) => ({
        ...crumb,
        labelTemplate: crumb.labelKey ? `breadcrumb.label.${kebabCase(crumb.labelKey)}` : undefined,
      })),
  }
}
