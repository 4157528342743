
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    atPageTop: { type: Boolean, required: false, default: false },
    iconLeft: { type: String, required: false, default: undefined },
    flatBottom: { type: Boolean, required: false, default: false },
    backgroundMobile: { type: Boolean, required: false, default: false },
    backgroundColor: { type: String, required: false, default: 'bg-white' },
    proseClass: { type: String, required: false, default: undefined },
    title: { type: String, required: false, default: undefined },
    subtitle: { type: String, required: false, default: undefined },
    showTitlesOnLargeScreens: { type: Boolean, required: false, default: true },
    introduction: { type: String, required: false, default: undefined },
    description: { type: String, required: false, default: undefined },
    date: { type: Date, required: false, default: undefined },
    theme: { type: String, required: false, default: undefined },
    mobileTheme: { type: String, required: false, default: undefined },
  },
})
