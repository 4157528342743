export const state = () => ({
  mobileFilterStatus: false,
})

export const mutations = {
  toggleMobileFilter(state) {
    state.mobileFilterStatus = !state.mobileFilterStatus
  },
  disableMobileFilter(state) {
    state.mobileFilterStatus = false
  },
}
