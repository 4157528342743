import { render, staticRenderFns } from "./Error.vue?vue&type=template&id=e6bbb96e"
import script from "./Error.vue?vue&type=script&lang=js"
export * from "./Error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculeMaskVisual: require('/home/userapp/components/Molecule/MaskVisual.vue').default,OrganismPageSectionContent: require('/home/userapp/components/Organism/PageSectionContent.vue').default,TemplateBase: require('/home/userapp/components/Template/Base.vue').default})
