export const state = () => ({
  enabled: false,
  translations: {},
  translationUrls: {},
})

export const getters = {
  isEnabled: (state) => state.enabled,
  translations: (state) =>
    Object.entries(state.translations)
      .filter(([key]) => !key.startsWith('control-panel.'))
      .sort(([a], [b]) => (a < b ? -1 : 1)),
  translationUrls: (state) => state.translationUrls,
}

export const mutations = {
  setTranslationUrls: (state, translationUrls) => (state.translationUrls = translationUrls),
  addTranslation: (state, { key, translated }) => (state.translations[key] = translated),
  clearTranslations: (state) => (state.translations = {}),
  reset: (state) => (state.enabled = false),
  toggle: (state) => (state.enabled = !state.enabled),
  enable: (state) => (state.enabled = true),
}

export const actions = {
  async fetchTranslationUrls({ commit, dispatch, getters }) {
    // Early return when the control-panel is disabled
    if (!getters.isEnabled) {
      return
    }

    const locale = this.$i18n.locale
    const key = `control-panel;translationUrls;locale:${locale}`
    const ttl = 300

    const fetcher = async () => {
      return (await this.$cmsFetch(`/api/${locale}/translation-urls.json`)).data
    }

    commit('setTranslationUrls', await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true }))
  },
}
