import debounce from 'lodash/debounce'
import groupBy from 'lodash/groupBy'
import xor from 'lodash/xor'
import { toDay, toHour } from '~/assets/date'
import { toCESTDateString } from '~/assets/util'

// Provide a single debounce that we can wrap around all fetch calls
const fetchDebounce = debounce((call) => call(), 300)

export const state = () => {
  return {
    // Global results (i.e. unaffected by search context)
    allFacets: {},

    // Search context
    composers: [],
    date: toHour('now').toISOString(),
    discounts: [],
    genres: [],
    instruments: [],
    keyword: '',
    page: 1,
    rooms: [],
    specials: [],
    tags: [],

    // Banner results
    fallbackBanners: [],
    banners: [],

    // Search results
    events: [], // Events ordered by EventDate
    facets: {},
    hits: [], // Event uri's ordered by EventDate
    loading: true,
    topEvents: [], // Events ordered by relevance
    topHits: [], // Event uri's ordered by relevance
    totalHits: 0,
  }
}

export const getters = {
  // Constants
  hitsPerPage: () => 15,

  // Search context
  composers: (state) => state.composers,
  date: (state) => state.date,
  dateIsSpecified: (state) => toHour(state.date).toISOString() !== toHour('now').toISOString(),
  discounts: (state) => state.discounts,
  genres: (state) => state.genres,
  instruments: (state) => state.instruments,
  keyword: (state) => state.keyword,
  page: (state) => state.page,
  rooms: (state) => state.rooms,
  specials: (state) => state.specials,
  tags: (state) => state.tags,

  // Url parameters
  urlParameters: (state) => ({
    composers: state.composers,
    date: state.date === toHour('now').toISOString() ? undefined : toCESTDateString(state.date),
    discounts: state.discounts,
    genres: state.genres,
    instruments: state.instruments,
    keyword: state.keyword || undefined,
    page: state.page === 1 ? undefined : state.page,
    rooms: state.rooms,
    specials: state.specials,
    tags: state.tags,
  }),

  // Banner results
  bannersAtPosition: (state) => {
    const { fallbackBanners, banners } = state
    return {
      // First the fallback banners
      ...Object.fromEntries(fallbackBanners.map((banner) => [banner.position, banner])),
      // Second the banners based on search criteria, these will overwrite the fallback banners
      ...Object.fromEntries(banners.map((banner) => [banner.position, banner])),
    }
  },

  // Search results
  facets: (state) => {
    const { allFacets, composers, discounts, facets, genres, instruments, rooms, specials } = state
    const helper = (fromAllFacets, fromFacets, activeList) => {
      const currentCountMap = Object.fromEntries((fromFacets || []).map(({ count, label, value }) => [value || label, count]))
      return (fromAllFacets || []).map(({ value, label }) => ({
        active: activeList.includes(value || label),
        count: currentCountMap[value || label] || 0,
        label,
        value: value || label,
      }))
    }
    return [
      { key: 'genres', values: helper(allFacets.genres, facets.genres, genres) },
      { key: 'rooms', values: helper(allFacets.rooms, facets.rooms, rooms) },
      { key: 'composers', values: helper(allFacets.composers, facets.composers, composers) },
      { key: 'instruments', values: helper(allFacets.instruments, facets.instruments, instruments) },
      { key: 'specials', values: helper(allFacets.specials, facets.specials, specials) },
      { key: 'discounts', values: helper(allFacets.discounts, facets.discounts, discounts) },
    ]
  },
  activeFacets: (state) => {
    const { allFacets, composers, discounts, genres, instruments, rooms, specials, tags } = state
    const helper = (fromAllFacets, activeList, facet) =>
      (fromAllFacets || []).filter(({ value, label }) => activeList.includes(value || label)).map(({ value, label }) => ({ facet, label, value: value || label }))
    return [
      // 'tags' are special as we do not display them as facets to the user, but they must be removable
      ...tags.map((value) => ({ facet: 'tags', label: value, value })),
      ...helper(allFacets.genres, genres, 'genres'),
      ...helper(allFacets.rooms, rooms, 'rooms'),
      ...helper(allFacets.composers, composers, 'composers'),
      ...helper(allFacets.instruments, instruments, 'instruments'),
      ...helper(allFacets.specials, specials, 'specials'),
      ...helper(allFacets.discounts, discounts, 'discounts'),
    ]
  },
  events: (state) => state.events,
  eventsByDay: (state) =>
    Object.entries(
      groupBy(
        state.events.map((event, index) => ({ ...event, index })),
        (event) => toDay(event.eventDate).toISOString()
      )
    ).map(([date, events]) => ({
      date,
      events: events.sort((a, b) => (a.eventDate === b.eventDate ? a.room.title.localeCompare(b.room.title) : new Date(a.eventDate) - new Date(b.eventDate))),
    })),
  hits: (state) => state.hits,
  loading: (state) => state.loading,
  topEvents: (state) => state.topEvents,
  topHits: (state) => state.topHits,
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Global results (i.e. unaffected by search context)
  setAllFacets: (state, allFacets) => (state.allFacets = allFacets),

  // Initialize
  initialize: (
    state,
    {
      composers,
      date,
      discounts,
      genres,
      instruments,
      keyword,
      page,
      rooms,
      search,
      specials,
      tags,
      // 'discounts' and 'rooms' were previously available using different parameters.
      // We take this into account for backwards compatability.
      importDiscountGroups: backwardCompatibleDiscounts,
      room: backwardCompatibleRooms,
    }
  ) => {
    const toArray = (value) => (value === undefined ? [] : Array.isArray(value) ? value : [value])
    const toBackwardCompatibleArray = (value, backwardCompatibleValue) => (value === undefined ? toArray(backwardCompatibleValue) : toArray(value))
    state.composers = toArray(composers)
    state.date = toHour(date, 'now').toISOString()
    state.discounts = toBackwardCompatibleArray(discounts, backwardCompatibleDiscounts)
    state.genres = toArray(genres)
    state.instruments = toArray(instruments)
    state.keyword = typeof keyword === 'string' ? keyword : typeof search === 'string' ? search : '' // For backwards compatability we fallback to the parameter 'search' for the keyword
    state.page = typeof page === 'string' ? Math.max(1, Number.parseInt(page)) : 1
    state.rooms = toBackwardCompatibleArray(rooms, backwardCompatibleRooms)
    state.specials = toArray(specials)
    state.tags = toArray(tags)
  },

  // Url parameters
  setUrlParameters(state, query) {
    const toArray = (value) => (value === undefined ? [] : Array.isArray(value) ? value : [value])
    const toValue = (value, fallback) => (value === undefined ? fallback : Array.isArray(value) ? value?.[0] : value)
    state.date = toHour(query.date, 'now').toISOString()
    state.discounts = toArray(query.discounts)
    state.genres = toArray(query.genres)
    state.instruments = toArray(query.instruments)
    state.keyword = toValue(query.keyword, '')
    state.page = Math.max(1, toValue(query.page, 1))
    state.rooms = toArray(query.rooms)
    state.specials = toArray(query.specials)
    state.tags = toArray(query.tags)
    this.dispatch('event-overview/fetch')
    this.dispatch('event-overview/fetchBanners')
  },

  // Search context
  reset(state) {
    // Search context
    state.composers = []
    state.date = toHour('now').toISOString()
    state.discounts = []
    state.genres = []
    state.instruments = []
    state.keyword = ''
    state.page = 1
    state.rooms = []
    state.specials = []
    // Banner results
    state.fallbackBanners = []
    state.banners = []
    // Search results
    state.events = []
    state.facets = {}
    state.hits = []
    state.loading = true
    state.topEvents = []
    state.topHits = []
    state.totalHits = 0
    // note that we do NOT reset the state.tags because this is not a facet that can be set by the user
    setTimeout(() => {
      this.dispatch('event-overview/fetch')
      this.dispatch('event-overview/fetchBanners')
    }, 10)
  },
  setDate(state, date) {
    state.date = toHour(date, 'now').toISOString()
    state.page = 1
    this.dispatch('event-overview/fetch')
  },
  setKeyword(state, keyword) {
    state.keyword = keyword
    state.page = 1
    // Debounce because we do not want to fetch on every keystroke
    fetchDebounce(() => this.dispatch('event-overview/fetch'))
  },
  toggleFacet(state, { name, value }) {
    switch (name) {
      case 'composers':
        state.composers = xor(state.composers, [value])
        state.page = 1
        this.dispatch('event-overview/fetch')
        this.dispatch('event-overview/fetchBanners')
        break
      case 'discounts':
        state.discounts = xor(state.discounts, [value])
        state.page = 1
        this.dispatch('event-overview/fetch')
        break
      case 'genres':
        // Vue.set(state, 'genres', xor(state.genres, [value]))
        state.genres = xor(state.genres, [value])
        state.page = 1
        this.dispatch('event-overview/fetch')
        this.dispatch('event-overview/fetchBanners')
        break
      case 'instruments':
        state.instruments = xor(state.instruments, [value])
        state.page = 1
        this.dispatch('event-overview/fetch')
        break
      case 'rooms':
        state.rooms = xor(state.rooms, [value])
        state.page = 1
        this.dispatch('event-overview/fetch')
        break
      case 'specials':
        state.specials = xor(state.specials, [value])
        state.page = 1
        this.dispatch('event-overview/fetch')
        this.dispatch('event-overview/fetchBanners')
        break
      case 'tags':
        state.tags = xor(state.tags, [value])
        state.page = 1
        this.dispatch('event-overview/fetch')
        break
      default:
        // eslint-disable-next-line no-console
        console.error(`Unable to toggle unknown facet "${name}" with value "${value}"`)
        break
    }
  },
  setPage(state, page) {
    state.page = Math.max(1, page)
    this.dispatch('event-overview/fetch')
  },

  // Banner results
  setFallbackBanners: (state, fallbackBanners) => (state.fallbackBanners = fallbackBanners),
  setBanners: (state, banners) => (state.banners = banners),

  // Search results
  setEvents: (state, events) => (state.events = events),
  setFacets: (state, facets) => (state.facets = facets),
  setHits: (state, hits) => (state.hits = hits),
  setLoading: (state, loading) => (state.loading = loading),
  setTopEvents: (state, topEvents) => (state.topEvents = topEvents),
  setTopHits: (state, topHits) => (state.topHits = topHits),
  setTotalHits: (state, totalHits) => (state.totalHits = totalHits),
}

export const actions = {
  async fetchFallbackBanners({ commit, dispatch }) {
    const key = `calendar-fallback-banners;${this.$i18n.locale}`
    const ttl = 3600

    const fetcher = async () => {
      return (await this.$cmsFetch(`/api/${this.$i18n.locale}/concertagenda/fallback-banners.json`)).data
    }

    const fallbackBanners = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: [] }, { root: true })
    commit('setFallbackBanners', fallbackBanners)
    return fallbackBanners
  },

  async fetchBanners({ commit, dispatch, getters }) {
    const { composers, genres, specials } = getters
    const key = `calendar-banners;${this.$i18n.locale};composers:${composers.join(',')};genres:${genres.join(',')};specials:${specials.join(',')}`
    const ttl = 3600

    // We only support showing special banners when only one of the facets is used
    let banners = []
    const sections = [composers.length > 0 ? 'composer' : undefined, genres.length > 0 ? 'genre' : undefined, specials.length > 0 ? 'special' : undefined].filter(
      (section) => section
    )
    if (sections.length === 1) {
      const fetcher = async () => {
        return (
          await this.$cmsFetch(
            `/api/${this.$i18n.locale}/banners/concertagenda/${sections[0]}/${[...composers, ...genres, ...specials]
              .map((value) => value.replace('/', '-SLASH-')) // There can be no slashes in the uri
              .join(',')}.json`
          )
        ).data
      }
      banners = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: [] }, { root: true })
    }

    commit('setBanners', banners)
    return banners
  },

  async fetchMonth({ commit, dispatch, getters }, { year, month }) {
    const key = `calendar-month;${this.$i18n.locale};${year}-${month}`
    const ttl = 900

    const fetcher = async () => {
      const date = new Date(`${year}/${month}/01`).toISOString()
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query eventsInMonth($site:[String], $eventEndDateRange:[String]) {
          elasticSearch(site:$site, section:"event", eventEndDateRange:$eventEndDateRange) {
            facets(limit:1024) {
              eventDate(interval:"day") {
                value
              }
            }
          }
        }`,
        variables: { site: `${this.$i18n.locale}Default`, eventEndDateRange: `from ${date}||-1d/d to ${date}||+1M+1d/d` },
      })
      return elasticSearch.facets.eventDate.map(({ value }) => new Date(parseInt(value, 10)).toISOString())
    }

    // Fetch the aggregated days
    return await dispatch('cache/fetch', { key, ttl, fetcher, fallback: [] }, { root: true })
  },

  async fetchAllFacets({ commit, dispatch }) {
    // We actually want to search for events after 'now' and also rounded to the last hour to allow for caching
    const actualDate = toHour('now').toISOString()
    const key = `calendar;${this.$i18n.locale};all-facets;date:${actualDate}`
    const ttl = 900

    const fetcher = async () => {
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query allEventFacets($site:[String], $eventEndDate:[String]) {
            elasticSearch(site:$site, section:"event", eventEndDateRange:$eventEndDate) {
              facets(limit:1024) {
                composers { label: value }
                discounts: importDiscountGroups { value }
                genres { label: value }
                instruments { label: value }
                rooms: room { label: value }
                specials { label: value }
              }
            }
          }`,
        variables: { site: `${this.$i18n.locale}Default`, eventEndDate: `gte ${actualDate}` },
      })
      return elasticSearch.facets
    }

    // Fetch the facets
    const allFacets = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: {} }, { root: true })
    commit('setAllFacets', allFacets)
    return allFacets
  },

  async fetch({ commit, dispatch, getters }) {
    const { composers, date, discounts, genres, hitsPerPage, instruments, keyword, page, rooms, specials, tags } = getters
    // We actually want to search for events after 'now' and also rounded to the last hour to allow for caching
    const actualDate = toHour(date).toISOString()
    const key = [
      'calendar',
      this.$i18n.locale,
      `keyword:${keyword}`,
      `date:${actualDate}`,
      `composers:${composers.join(',')}`,
      `discounts:${discounts.join(',')}`,
      `genres:${genres.join(',')}`,
      `instruments:${instruments.join(',')}`,
      `rooms:${rooms.join(',')}`,
      `specials:${specials.join(',')}`,
      `tags:${tags.join(',')}`,
    ].join(';')
    const ttl = 300

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      // For the topEvents we just retrieve the first few
      const { events, topEvents } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query searchEvents($site:[String], $eventEndDate:[String], $keyword:String, $composers:[String], $discounts:[String], $genres:[String], $importIconcertStatus: [String], $instruments:[String], $rooms:[String], $specials:[String], $tags:[String]) {
          events: elasticSearch(site:$site, section:"event", fuzzyness:"0", eventEndDateRange:$eventEndDate, search:$keyword, composersFacet:$composers, importDiscountGroupsFacet:$discounts, genresFacet:$genres, importIconcertStatusFacet: $importIconcertStatus, instrumentsFacet:$instruments, roomFacet:$rooms, specialsFacet:$specials, tagsFacet:$tags, orderBy:"eventDate ASC") {
            facets(limit:1024) {
              composers { label: value, count }
              discounts: importDiscountGroups { value, count }
              genres { label: value, count }
              instruments { label: value, count }
              rooms: room { label: value, count }
              specials { label: value, count }
            }
            count
            hits(limit:1024) {
             uri
            }
          }
          topEvents: elasticSearch(site:$site, section:"event", eventEndDateRange:$eventEndDate, search:$keyword, composersFacet:$composers, importDiscountGroupsFacet:$discounts, genresFacet:$genres, importIconcertStatusFacet: $importIconcertStatus, instrumentsFacet:$instruments, roomFacet:$rooms, specialsFacet:$specials, tagsFacet:$tags) {
            hits(limit:5) {
             uri
            }
          }
        }`,
        variables: {
          site: `${this.$i18n.locale}Default`,
          eventEndDate: `gte ${actualDate}`,
          keyword,
          composers,
          discounts,
          genres,
          importIconcertStatus: ['1e Optie', 'Bevestigd', 'Contract uit', 'Corona', 'Voor brochure', 'Verplaatst', 'Geannuleerd'],
          instruments,
          rooms,
          specials,
          tags,
        },
      })

      return {
        facets: events.facets,
        hits: events.hits.map(({ uri }) => uri),
        topHits: topEvents.hits.map(({ uri }) => uri),
        totalHits: events.count,
      }
    }

    // Fetch the hits
    const { facets, hits, topHits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { facets: {}, hits: [], topHits: [], totalHits: 0 } }, { root: true })
    commit('setFacets', facets)
    commit('setHits', hits)
    commit('setTopHits', topHits)
    commit('setTotalHits', totalHits)

    // todo: add loaders
    // Initialize the series list with N empty elements, used to trigger loaders
    const slice = hits.slice((page - 1) * hitsPerPage, page * hitsPerPage)
    // commit(
    //   'setEvents',
    //   slice.map(() => undefined)
    // )

    // Fetch all associated entries within the current page
    const events = await Promise.all(slice.map((uri) => dispatch('page/fetchUri', { uri }, { root: true })))
    commit(
      'setEvents',
      events.filter((event) => event)
    )

    // Fetch all associated top hits when a keyword search was done
    // todo: temporarily disable the topEvents, this should be re-enabled once Stijn accepts the change
    // if (keyword) {
    //   const topEvents = await Promise.all(topHits.map((uri) => dispatch('page/fetchUri', { uri }, { root: true })))
    //   commit('setTopEvents', topEvents)
    // } else {
    //   commit('setTopEvents', [])
    // }

    commit('setLoading', false)
    return events
  },
}
