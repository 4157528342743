import { educationSchoolTypes } from '~/assets/composition/tix'

export const state = () => ({
  order: undefined,
  orderSize: undefined,
  profile: undefined,
  user: undefined,
  tixEnv: 'tix',
  isEducationCustomer: false,
  customerId: null,
})

export const getters = {
  isEducationCustomer: (state) => state.isEducationCustomer,
  tixEnv: (state) => state.tixEnv,
}

export const mutations = {
  setTixEnv: (state, tixEnv) => {
    switch (tixEnv) {
      case 'tix':
      case 'education':
        state.tixEnv = tixEnv
        break
      default:
        // eslint-disable-next-line no-console
        console.error('invalid tix env var given')
    }
  },
  update(state, { order, profile, user }) {
    const userTagIds = user?.tags.map((tag) => tag.id) ?? []
    // Note that keys will always be converted to String
    const educationTagIds = Object.keys(educationSchoolTypes)

    state.order = order
    state.orderSize = order?.length || 0
    state.profile = profile
    state.user = user
    state.isEducationCustomer = state.tixEnv === 'education' && userTagIds.some((tagId) => educationTagIds.includes(String(tagId)))
  },
  setCustomerId(state, customerId) {
    state.customerId = customerId
  },
}
