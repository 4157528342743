/* /////////////////////////////////////////////
// (C)opyright 2020 LessonUp.com            //
// https://lessonup.com                     //
//////////////////////////////////////////////

* LessonUp.open
  opens the dialog and loads the lesson passed as parameter in the iframe.

  params:
    lessonId : the uri-safe string containing the LessonUp lessonId (required)
    language : interface language  (optional)
      posible values: (nl|en)
      default: nl

    example:
      LessonUp.open('9wQss8dNkvL47zAkf')

* LessonUp.hide
  hides the dialog again

  example: LessonUp.hide()

* LessonUp.show
  shows the lessonUp Embed dialog

  example: LessonUp.show()
*/

window.LessonUp = new (function () {
  const self = this
  self.initialized = false

  const OVERLAY_HTML = '<div id="lessonUpPageOverlay" onclick="LessonUp.hide()"></div>'
  const DIALOG_HTML =
    '<div id="lessonUpEmbedPlayer"><iframe id="lessonUpEmbedPlayerIframe" src="about:blank" allowfullscreen="true"></iframe><a id="lessonUpCloseButton" href="#" title="sluiten" alt="sluiten" onclick="LessonUp.hide(); return false;">&#x2715;</a></div>'

  const CSS =
    '#lessonUpPageOverlay{opacity:.6;background-color:#000;z-index:42000;position:fixed;top:0;bottom:0;left:0;right:0}#lessonUpEmbedPlayer{position:fixed;top:40px;bottom:40px;left:40px;right:40px;clear:both;z-index:42001;background-color:#fff;max-width:1020px;margin:0 auto}#lessonUpEmbedPlayer.lu-full-size{top:0;bottom:0;left:0;right:0;max-width:100%}#lessonUpEmbedPlayer.lu-full-size #lessonUpCloseButton{display:none}#lessonUpEmbedPlayer iframe{border:none;margin:0;height:100%;width:100%}#lessonUpEmbedPlayer #lessonUpCloseButton{width:3.125rem;height:3.125rem;line-height:3.125rem;border-radius:1.5625rem;background-color:#fff;color:#000;position:absolute;top:-25px;right:-25px;margin:0;padding:0;cursor:pointer;text-align:center;text-decoration:none!important;box-shadow:none!important;border:0}#lessonUpEmbedPlayer #lessonUpCloseButton:hover{background-color:#000;color:#fff}'

  function playerDom() {
    return document.getElementById('lessonUpEmbedPlayer')
  }

  function iframeDom() {
    return document.getElementById('lessonUpEmbedPlayerIframe')
  }

  function overlayDom() {
    return document.getElementById('lessonUpPageOverlay')
  }

  function lessonUrl(lessonId, language) {
    const lang = language || 'nl'
    return ['https://www.lessonup.com/', lang, '/embed/', lessonId].join('')
  }

  function init() {
    // make sure css and html is loaded
    if (self.initialized) return

    const el1 = document.createElement('div')
    document.body.appendChild(el1)
    el1.outerHTML = OVERLAY_HTML

    const el2 = document.createElement('div')
    document.body.appendChild(el2)
    el2.outerHTML = DIALOG_HTML

    const el3 = document.createElement('style')
    el3.appendChild(document.createTextNode(CSS))
    document.head.appendChild(el3)

    if (window.addEventListener) {
      window.addEventListener('message', handleIframeMessage, false)
    }

    self.initialized = true
  }

  function handleIframeMessage(event) {
    if (event.origin !== 'https://www.lessonup.com') return
    try {
      const data = JSON.parse(event.data)
      if (data.action === 'presentationMode') {
        if (data.msg === 'start') {
          playerDom().classList.add('lu-full-size')
        } else {
          playerDom().classList.remove('lu-full-size')
        }
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  function open(lessonId, language) {
    if (!lessonId) return
    init()
    const iframe = iframeDom()
    iframe.src = lessonUrl(lessonId, language)

    window.setTimeout(function () {
      show()
    })
  }

  function setVisible(visible) {
    const display = visible ? 'block' : 'none'
    overlayDom().style.display = display
    playerDom().style.display = display

    if (!visible) {
      // clear iframe again
      iframeDom().src = 'about:blank'
    }
  }

  function show() {
    setVisible(true)
  }

  function hide() {
    setVisible(false)
  }

  this.init = init
  this.open = open
  this.show = show
  this.hide = hide
})()
