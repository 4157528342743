export const state = () => ({
  cacheKey: undefined,
})

export const getters = {
  get(state, getters, rootState, rootGetters) {
    return rootGetters['cache/get'](state.cacheKey)
  },
}

export const mutations = {
  cacheKey(state, key) {
    state.cacheKey = key
  },
}

export const actions = {
  async fetch({ commit, dispatch }, { path }) {
    const key = `redirects;locale:${this.$i18n.locale};uri:${path}`
    const ttl = 3600

    const fetcher = async () => {
      return await this.$cmsFetch(`/api/seo-helper/${this.$i18n.locale}Default/find-redirects.json?path=${path}`)
    }

    try {
      return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    } finally {
      commit('cacheKey', key)
    }
  },
}
