
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    image: { type: Object, required: false, default: undefined },
    hasVideo: { type: Boolean, required: false, default: false },
    shapeNumber: { type: Number, required: false, default: 1, validator: (value) => [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(value) },
  },
})
