
import { defineComponent, useStore } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'
import { setupGtmPageView, setupGtmUserInfo } from '~/assets/composition/setup-gtm'
import { setupTix } from '@/assets/composition/tix'
import { setupTranslationPrefixer } from '@/assets/util'

export default defineComponent({
  props: {
    page: { type: Object, required: false, default: () => ({}) },
  },
  setup(props) {
    const { t_ } = setupTranslationPrefixer('favorites')
    const store = useStore()
    const favoritesCloseOverlay = () => {
      store.commit('favorites/closeOverlay')
    }
    // setupBodyOverflow() // does not work properly!! it also adds the class overflow-hidden on desktop which causes serious UX problems on the agenda when you reset the filters! commented until further notice...
    setupGtmPageView(props.page)
    setupGtmUserInfo()
    return {
      ...setupTix(),
      favoritesCloseOverlay,
      t_,
    }
  },
  computed: {
    ...mapGetters({
      overlayIsOpen: 'favorites/overlayIsOpen',
    }),
  },
})
