export function selectRandomTree(recommendationTrees = []) {
  if (recommendationTrees.length === 0) {
    return {
      tree: null,
      treeIndex: -1,
    }
  }

  const treeIndex = Math.floor(Math.random() * recommendationTrees.length)
  const tree = recommendationTrees[treeIndex]
  return { tree, treeIndex }
}

export function reformatTree(tree) {
  if (!tree?.answer_tags) {
    return tree
  }

  const reformattedTree = Object.entries(tree.answer_tags).reduce((acc, [key, value]) => {
    acc[key] = reformatTree(value)
    return acc
  }, {})

  return { [tree.question_tag]: reformattedTree }
}

export function getRecommendationTree(recommendationTrees = []) {
  const { tree, treeIndex } = selectRandomTree(recommendationTrees)

  // Reformat tree to a more simplified structure to make it usable with lodash get
  const reformattedTree = reformatTree(tree)

  return {
    tree: reformattedTree,
    treeIndex,
  }
}
