import { defineNuxtPlugin } from '@nuxtjs/composition-api'

async function jsonFetch(context, url, options) {
  let response
  let payload
  try {
    response = await fetch(url, options)
    if (response.status < 200 || response.status >= 300) {
      try {
        payload = await response.json()
      } catch {}
      const messages = [payload?.error?.message, payload?.message].filter((m) => m).join(' ')
      throw new Error(`Unable to fetch "${url}": ${response.status} ${response.statusText}.` + (messages ? ` ${messages}` : ''))
    }
    payload = await response.json()
    return payload
  } finally {
    if (context.isDev) {
      /* eslint-disable no-console */
      if (process.client) {
        console.groupCollapsed(url)
        console.info(response)
        console.info(payload)
        console.groupEnd()
      } else {
        console.info(url)
      }
    }
    /* eslint-enable no-console */
  }
}

function constructUrl(context, url, query) {
  const urlConstruct = new URL(url)

  if (query !== undefined && typeof query === 'object') {
    Object.entries(query).forEach(([key, value]) => urlConstruct.searchParams.append(key, value))
  } else if (query !== undefined && typeof query === 'string') {
    Array.from(new URLSearchParams(query).entries()).forEach(([key, value]) => urlConstruct.searchParams.append(key, value))
  }

  const preview = context.query['x-craft-preview'] || context.query['x-craft-live-preview'] || undefined
  if (preview && context.query.token) {
    urlConstruct.searchParams.append('preview', preview)
    urlConstruct.searchParams.append('token', context.query.token)
  }

  return urlConstruct.toString()
}

export default defineNuxtPlugin((context, inject) => {
  // bridge requests: POST, PUT requests must also be allowed
  inject('bridgeFetch', async (uri, { query, ...fetchOptions } = {}) => {
    const url = constructUrl(context, context.$config.bridgeSiteUrl + uri, query)
    return await jsonFetch(context, url, fetchOptions)
  })

  // CMS API endpoint
  inject('cmsFetch', async (uri, { query, ...fetchOptions } = {}) => {
    const url = constructUrl(context, context.$config.cmsSiteUrl + uri, query)
    return await jsonFetch(context, url, fetchOptions)
  })

  // GraphQL endpoint
  inject('graphqlFetch', async ({ query, variables, token }) => {
    const tokenMap = {
      elasticsearch: context.$config.elasticsearchGraphqlPublicToken,
      formie: context.$config.formieGraphqlPublicToken,
    }

    let response
    let payload
    try {
      response = await fetch(`${context.$config.cmsSiteUrl}/api`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...(tokenMap[token] ? { Authorization: `Bearer ${tokenMap[token]}` } : {}),
        },
        body: JSON.stringify({ query, variables }),
      })

      if (response.status < 200 || response.status >= 300) {
        throw new Error(`GraphQL error: invalid status ${response.status}`)
      }

      payload = await response.json()
      if (payload?.errors?.length) {
        throw new Error(`GraphQL error: ${payload?.errors?.[0]?.message || '-'}`)
      }

      return payload?.data
    } finally {
      if (context.isDev) {
        /* eslint-disable no-console */
        if (process.client) {
          console.groupCollapsed('graphqlFetch')
          console.info(`token: ${token}`)
          console.info(query)
          console.info(variables)
          console.info(response)
          console.info(payload)
          console.groupEnd()
        } else {
          console.info('graphqlFetch')
        }
        /* eslint-enable no-console */
      }
    }
  })
})
