export const state = () => ({
  // The current path, used to determine what the active navigation element is
  fullPath: undefined,

  // The id of the current navigation item that is toggled open
  openItemId: undefined,

  // Navigation elements
  navigation: { bottom: [], footer: [], menu: [], newsletter: [], service: [] },
})

const annotateNavigationItems = (items, openItemId, fullPath) => {
  return (
    items?.map((item) => {
      const children = annotateNavigationItems(item.children, openItemId, fullPath)
      return {
        ...item,
        isOpen: children.length && (children.some((child) => child.isOpen) || item.id === openItemId),
        isActive: children.some((child) => child.isActive) || (item.url && item.url?.match(/^(https?:\/\/[^/]+)?(?<fullPath>.*)/i)?.groups.fullPath === fullPath),
        children,
      }
    }) || []
  )
}

export const getters = {
  bottom: (state) => annotateNavigationItems(state.navigation.bottom, state.openItemId, state.fullPath),
  footer: (state) => annotateNavigationItems(state.navigation.footer, state.openItemId, state.fullPath),
  menu: (state) => annotateNavigationItems(state.navigation.menu, state.openItemId, state.fullPath),
  newsletter: (state) => annotateNavigationItems(state.navigation.newsletter, state.openItemId, state.fullPath),
  service: (state) => annotateNavigationItems(state.navigation.service, state.openItemId, state.fullPath),
}

export const mutations = {
  closeAll: (state) => (state.openItemId = undefined),
  setNavigation: (state, navigation) => (state.navigation = navigation),
  setRoute: (state, route) => (state.fullPath = route.fullPath),
  toggleOpen: (state, id) => (state.openItemId = state.openItemId === id ? undefined : id),
}
