
import { defineComponent, ref, computed } from '@nuxtjs/composition-api'
import { getMediaImage } from '~/assets/media'

export default defineComponent({
  props: {
    image: { type: Object, required: false, default: undefined },
    alt: { type: String, required: false, default: undefined },
    imgClass: { type: String, required: false, default: '' },
    lazy: { type: Boolean, required: false, default: true },
  },
  setup(props) {
    const loading = ref(true)
    const skeleton = ref(null)
    const media = computed(() => {
      return getMediaImage(props.image)
    })
    const stopLoading = () => {
      loading.value = false
    }

    // add @load="stopLoading()" on the image src

    // onMounted(() => {
    //   skeleton.value.style.height = img.height + 'px'
    //   skeleton.value.style.width = img.width + 'px'
    // })

    return {
      stopLoading,
      skeleton,
      loading,
      media,
      source: props.image?.source,
    }
  },
})
