
import { computed, defineComponent, ref, useRouter, useStore } from '@nuxtjs/composition-api'
import { mapGetters, mapMutations } from 'vuex'
import { setupTix } from '~/assets/composition/tix'
import { setupGtmNavigationClick } from '~/assets/composition/setup-gtm'
import { setupTranslationPrefixer } from '@/assets/util'
import { explodeUrl } from '@/assets/link'

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const { t_ } = setupTranslationPrefixer('navigation')

    const menuIsOpened = ref(false)
    const searching = ref(false)
    const searchInputMobile = ref(null)

    const toggleMenuIsOpened = () => {
      searching.value = false
      menuIsOpened.value = !menuIsOpened.value

      if (menuIsOpened.value) {
        /*
         * BELOW IS COMMENTED FOR NOW, IT DOES NOT WORK PROPERLY YET AND BECAME A SHOWSTOPPER FOR THE SITE TO GO LIVE
         */
        // document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    }

    const submitSearch = (value) => {
      toggleSearching()
      searching.value = false
      menuIsOpened.value = false
      document.body.classList.remove('overflow-hidden')

      const path = explodeUrl(store.getters['global/search'].url).path
      router.push({ path, query: { search: value } })
    }

    const toggleSearching = () => {
      searching.value = !searching.value

      if (searching.value) {
        menuIsOpened.value = false
        document.body.classList.add('overflow-hidden')

        setTimeout(() => {
          searchInputMobile.value.focus()
        }, 100)
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    }

    const favoritesOpenOverlay = () => {
      store.commit('favorites/openOverlayAndStoreId')
    }

    return {
      ...setupTix(),
      menuIsOpened,
      navigationClick: setupGtmNavigationClick(),
      searching,
      searchInputMobile,
      submitSearch,
      toggleMenuIsOpened,
      toggleSearching,
      favoritesEnabled: computed(() => store.getters['favorites/enabled']),
      favoritesOpenOverlay,
      t_,
    }
  },
  computed: {
    ...mapGetters({
      menu: 'navigation/menu',
      serviceMenu: 'navigation/service',
    }),
  },
  methods: {
    ...mapMutations({
      toggleOpen: 'navigation/toggleOpen',
    }),
  },
})
