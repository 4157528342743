import { get, flatten } from 'lodash'

import { getRecommendationTree } from '@/assets/recommendations'

export const recommendationTemplateType = {
  START: 'start',
  QUESTIONNAIRE: 'questionnaire',
  SUGGESTIONS: 'suggestions',
  LOADING: 'loading',
}

export const state = () => ({
  tree: null,
  treeIndex: null,
  questions: null,
  answers: null,
  selection: {},
  selectionPath: [],
  activeTemplate: null,
  recommendationEvents: {},
  recommendationEventUris: [],
})

export const getters = {
  tree: (state) => state.tree,
  questions: (state) => state.questions,
  answers: (state) => state.answers,
  selection: (state) => state.selection,
  selectionPath: (state) => state.selectionPath,
  activeTemplate: (state) => state.activeTemplate,
  recommendationEvents: (state) => state.recommendationEvents,
  recommendationEventUris: (state) => state.recommendationEventUris,
}

export const mutations = {
  setRecommendationTree(state, { questions, answers, tree }) {
    state.questions = questions
    state.answers = answers
    state.tree = tree
  },
  setRecommendationTreeIndex(state, { index }) {
    state.treeIndex = index
  },
  initSelectionPath: (state) => {
    state.selectionPath = [[Object.keys(state.tree)[0]]]
  },
  setSelection(state) {
    const currentPath = state.selectionPath
    const lastNode = currentPath[currentPath.length - 1]

    // Use selectionPath to find the current question key
    if (lastNode.length > 1) {
      const treePath = flatten(currentPath)
      const [questionKey] = Object.keys(get(state.tree, treePath, null) || {})

      if (!questionKey) {
        this.dispatch('recommendations/getRecommendations', { selectionPath: currentPath })
        return
      }

      state.selectionPath.push([questionKey])
    }

    const [questionKey] = currentPath[currentPath.length - 1]
    const question = state.questions[questionKey]

    const treePath = flatten(currentPath)
    const answersKeys = Object.keys(get(state.tree, treePath, state.tree))
    const answers = answersKeys.map((key) => ({
      ...state.answers[key],
      id: key,
      answer: state.answers[key][`answer_${this.$i18n.locale}`],
      description: state.answers[key][`description_${this.$i18n.locale}`],
    }))

    state.selection = {
      question: {
        ...question,
        question: question[`content_${this.$i18n.locale}`],
        description: question[`description_${this.$i18n.locale}`],
      },
      answers,
    }
  },
  setActiveTemplate: (state, templateType) => {
    state.activeTemplate = templateType
  },
  reset(state) {
    state.selectionPath = []
    state.activeTemplate = recommendationTemplateType.START
  },
  back(state) {
    if (state.selectionPath.length > 1) {
      // Remove last question entry
      state.selectionPath.pop()
      // Remove answer of previous question
      const lastIndex = state.selectionPath.length - 1
      state.selectionPath[lastIndex].pop()
    } else {
      state.activeTemplate = recommendationTemplateType.START
    }
  },
  next(state, answerId) {
    const lastIndex = state.selectionPath.length - 1
    state.selectionPath[lastIndex].push(answerId)
  },
  setRecommendationEventUris(state, eventUris) {
    state.recommendationEventUris = eventUris
  },
  setRecommendationEvents(state, recommendationEvents) {
    state.recommendationEvents = recommendationEvents
  },
}

export const actions = {
  async loadRecommendationData({ commit }) {
    const { questions, answers, trees } = await import('@/static/keuzehulp_input.json')

    const { tree, treeIndex } = getRecommendationTree(trees || [])

    if (!questions || !answers || !tree) {
      throw new Error('Could not load recommendation tree. Ensure questions, answers, and tree data are available.')
    }

    commit('setRecommendationTree', {
      questions,
      answers,
      tree,
    })
    commit('setRecommendationTreeIndex', { index: treeIndex })
    commit('setActiveTemplate', recommendationTemplateType.START)
  },
  async getRecommendations({ commit }, { selectionPath }) {
    try {
      commit('setActiveTemplate', recommendationTemplateType.LOADING)
      const key = `recommendations;${this.$i18n.locale};${selectionPath.map((path) => path.join(':')).join(';')}`
      const ttl = 900

      const fetcher = async () => {
        const recommendationEventsIds = await this.$bridgeFetch('/recommendations', {
          method: 'POST',
          headers: { Accept: 'application/json' },
          body: JSON.stringify(
            selectionPath.reduce((acc, [questionKey, answerKey]) => {
              acc[questionKey] = answerKey
              return acc
            }, {})
          ),
        })
        commit('setRecommendationEvents', recommendationEventsIds)

        const sortedKeys = Object.entries(recommendationEventsIds?.recommended_concerts || {})
          .sort((a, b) => b[1] - a[1])
          .map((entry) => entry[0])

        const { hitQuery } = await this.$graphqlFetch({
          token: 'elasticsearch',
          query: `query recommendationsEventSearch($importIconcertIdentifier: [String], $site: [String]) {
              hitQuery: elasticSearch(importIconcertIdentifierFacet: $importIconcertIdentifier, site: $site section: "event") {
                count
                hits(limit: 1024){
                  uri
                  importIconcertIdentifier
                }
              }
            }
          `,
          variables: {
            importIconcertIdentifier: sortedKeys,
            site: `${this.$i18n.locale}Default`,
          },
        })

        return hitQuery.hits.sort((a, b) => sortedKeys.indexOf(a.importIconcertIdentifier) - sortedKeys.indexOf(b.importIconcertIdentifier))
      }

      const hits = await this.dispatch('cache/fetch', { key, ttl, fetcher, fallback: {} }, { root: true })

      commit('setRecommendationEventUris', hits)
      commit('setActiveTemplate', recommendationTemplateType.SUGGESTIONS)
    } catch (e) {
      console.error('Error in getRecommendations:', e)
      commit('reset')
    }
  },
  async storeUserScores({ commit }, { recommendationEvents, user, email }) {
    try {
      await this.$bridgeFetch('/user-score/save', {
        method: 'POST',
        headers: { Accept: 'application/json' },
        body: JSON.stringify({
          userScore: recommendationEvents,
          ...(email && { email }),
          ...(user?.id && user?.hash && { userId: user.id, userHash: user.hash }),
        }),
      })
    } catch (e) {
      console.error('Error in storeUserScores:', e)
    }
  },
}
